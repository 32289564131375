import { DOTS, usePagination } from '@/hooks'
import { Button } from 'antd'
import { memo } from 'react'

type PaginationBarProps = {
  currentPage: number
  pageCount: number
  pageSize: number
  totalCount: number
  onClickPrev: () => void
  onClickPageNumber: (page: number) => void
  onClickNext: () => void
}

export const PaginationBar = memo((props: PaginationBarProps) => {
  const {
    currentPage,
    pageCount,
    pageSize,
    totalCount,
    onClickPrev,
    onClickPageNumber,
    onClickNext
  } = props

  const paginationRange = usePagination({ currentPage, pageSize, totalCount })

  return (
    <div className='col-xl-9'>
      <nav aria-label='Blog Pagination'>
        <ul className='pagination style-1 p-t20'>
          <li className='page-item'>
            <Button
              onClick={onClickPrev}
              className='page-link prev'
              disabled={currentPage === 1}
            >
              {'<'}
            </Button>
          </li>
          {paginationRange?.map((e, i) => {
            const isActive = currentPage === e
            return (
              <li key={i} className='page-item'>
                <Button
                  className='page-link'
                  disabled={isActive || e === DOTS}
                  onClick={() => onClickPageNumber(e as number)}
                  style={{
                    backgroundColor: isActive ? '#1a1668' : '',
                    color: isActive ? 'white' : ''
                  }}
                >
                  {e}
                </Button>
              </li>
            )
          })}
          <li className='page-item'>
            <Button
              disabled={currentPage === pageCount}
              onClick={onClickNext}
              className='page-link next'
            >
              {'>'}
            </Button>
          </li>
        </ul>
      </nav>
    </div>
  )
})
