import { STORAGE_KEY } from '@/constants'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

const uncheck = ['auth/local', 'auth/local/register']
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
    // 'x-api-key': `${process.env.REACT_APP_API_KEY}`,
  }
})

axiosInstance.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)
    if (accessToken) {
      config.headers.setAuthorization(`Bearer ${accessToken}`)
    } else {
      if (!uncheck.includes(config?.url || ''))
        config.headers.setAuthorization(
          `Bearer ${process.env.REACT_APP_API_KEY}`
        )
    }

    if (process.env.NODE_ENV === 'development') {
      // const { method, url, baseURL, data, params, headers } = config
      // console.log('<<== REQUEST INFO ==>>')
      // console.log('<<==================================>>')
      // console.log('URL:', `${baseURL}${url}`)
      // console.log('METHOD:', method)
      // params && console.log('PARAMS:', JSON.stringify(params, null, 1))
      // data && console.log('DATA:', JSON.stringify(data, null, 1))
      // accessToken && console.log('TOKEN:', headers.Authorization)
    }
    return config
  },

  function (error) {
    // console.error('REQUEST ERROR:', error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    if (process.env.NODE_ENV === 'development') {
      // const { config, data } = response
      // console.log('<<== RESPONSE ==>>')
      // console.log('<<==================================>>')
      // console.log('URL:', `${config.baseURL}${config.url}`)
      // console.log('DATA', JSON.stringify(data, null, 1))
    }
    return response
  },

  function (error) {
    // if (!error.response) {
    //   console.log('NETWORK ERROR!')
    //   error.response = {
    //     data: {
    //       error: 'REQUEST TIMEOUT !!',
    //       message: 'NETWORK ERROR!'
    //     }
    //   }
    // }
    // console.log('<<== ERROR RESPONSE ==>>')
    // console.log('<<==================================>>')
    // console.log(JSON.stringify(error.response.data, null, 1))
    return Promise.reject(error.response.data)
  }
)

type TAxiosRequest<K> = {
  path: string
  header?: AxiosRequestConfig['headers']
  params?: AxiosRequestConfig['params']
  data?: AxiosRequestConfig<K>['data']
}
export const axiosClient = {
  get: <T, K>(props: TAxiosRequest<K>): Promise<AxiosResponse<T>> => {
    return axiosInstance.get<T>(props.path, {
      params: props.params
    })
  },
  post: <T, K>(props: TAxiosRequest<K>): Promise<AxiosResponse<T>> => {
    return axiosInstance.post<T>(props.path, props.data, {
      headers: { ...props.header },
      params: props.params
    })
  },
  patch: <T, K>(props: TAxiosRequest<K>): Promise<AxiosResponse<T>> => {
    return axiosInstance.patch<T>(props.path, props.data, {
      params: props.params
    })
  },
  delete: <T, K>(props: TAxiosRequest<K>): Promise<AxiosResponse<T>> => {
    return axiosInstance.delete<T>(props.path, {
      params: props.params
    })
  }
}
