import {
  CheckBoxItem,
  PaginationBar,
  ProductItem,
  PublicTimeSelect
} from '@/components'
import { ROUTER } from '@/constants'
import {
  useAppDispatch,
  useAppSelector,
  usePrevious,
  useWindowDimensions
} from '@/hooks'
import { selectProductState, selectTopicState } from '@/redux/slices'
import { getListProductThunk, getListTopicFilterThunk } from '@/redux/thunks'
import styles from '@/styles/page/ListProductPageView.module.scss'
import { memo, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as FilterIcon } from '@/assets/icons/filterIcon.svg'

export const ListProductsPageView = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    listProduct,
    listProductPagination,
    fetchingListProduct,
    errorFetchListProduct
  } = useAppSelector(selectProductState)

  const [isOpenFilter, setIsOpenFilter] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const { width } = useWindowDimensions()

  const _prevFetchingListProduct = usePrevious(fetchingListProduct)

  const _toggleFilter = () => setIsOpenFilter((prev) => !prev)

  const _onClickProduct = useCallback(
    (productId: number, listTopicId?: number[]) => {
      if (listTopicId?.length) {
        return navigate({
          pathname: ROUTER.PRODUCT_DETAIL,
          search: `id=${productId}&topic=${listTopicId?.toString()}`
        })
      }
      return navigate({
        pathname: ROUTER.PRODUCT_DETAIL,
        search: `id=${productId}`
      })
    },
    []
  )

  const _onClickPrev = useCallback(() => {
    const _prevPage = (listProductPagination?.page ?? 0) - 1
    setSearchParams((searchParams) => {
      searchParams.set('page', _prevPage.toString())
      return searchParams
    })
  }, [listProductPagination?.page, searchParams])

  const _onClickPageNumber = useCallback(
    (page: number) => {
      setSearchParams((searchParams) => {
        searchParams.set('page', page.toString())
        return searchParams
      })
    },
    [searchParams]
  )

  const _onClickNext = useCallback(() => {
    const _nextPage = (listProductPagination?.page ?? 0) + 1
    setSearchParams((searchParams) => {
      searchParams.set('page', _nextPage.toString())
      return searchParams
    })
  }, [listProductPagination?.page, searchParams])

  useEffect(() => {
    if (_prevFetchingListProduct && !fetchingListProduct) {
      if (!errorFetchListProduct) {
        window.scrollTo(0, 0)
      }
    }
  }, [_prevFetchingListProduct, errorFetchListProduct])

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(
      getListProductThunk({
        populate: 'topics,cover_image',
        listTopicId: searchParams.get('topic')?.split(','),
        page: Number(searchParams.get('page')),
        pageSize: Number(searchParams.get('pageSize')),
        sort: searchParams.get('sort') ?? 'createdAt:desc'
      })
    )
  }, [searchParams])

  return (
    <div className='page-content bg-grey'>
      <div className='content-inner border-bottom'>
        <div className='container' style={{ maxWidth: width - 80 }}>
          <div className={`d-flex gap-4 flex-wrap ${styles.contentContainer}`}>
            {isOpenFilter && (
              <div style={{ flex: 1 }}>
                <div className={styles.filterButtonWrapper}>
                  <h4 className='title'>Bộ lọc</h4>
                  <a onClick={_toggleFilter} className={styles.filterButton}>
                    <FilterIcon />
                  </a>
                </div>
                <FilterSection className={styles.filterContainer1} />
              </div>
            )}
            <div style={{ flex: 4 }}>
              <div className='d-flex gap-3'>
                {!isOpenFilter && (
                  <a
                    onClick={_toggleFilter}
                    className={`${styles.filterButton} ${styles.filterButtonCollapse}`}
                  >
                    <FilterIcon style={{ transform: 'rotate(180deg)' }} />
                  </a>
                )}
                <h4 className='title'>Sản phẩm</h4>
              </div>
              {isOpenFilter && (
                <FilterSection className={styles.filterContainer2} />
              )}
              <div className='filter-area m-b30'>
                <div className='grid-area'></div>
                <div className='category py-2'>
                  <PublicTimeSelect
                    defaultValue={'createdAt:desc'}
                    options={[
                      { value: 'createdAt:desc', label: 'Mới nhất' },
                      { value: 'createdAt:asc', label: 'Cũ nhất' },
                      { value: 'name:asc', label: 'A-Z' },
                      { value: 'name:desc', label: 'Z-A' }
                    ]}
                    onChange={(value) => {
                      setSearchParams((searchParams) => {
                        searchParams.set('sort', value)
                        return searchParams
                      })
                    }}
                  />
                </div>
              </div>
              <div className={styles.productGrid}>
                {fetchingListProduct &&
                  Array.from(Array(12).keys()).map((e) => {
                    return (
                      <div key={e} className='col-book style-2'>
                        <div
                          className='skeleton'
                          style={{
                            // aspectRatio: 16 / 9,
                            height: Math.floor(
                              Math.random() * (200 - 150) + 150
                            ),
                            borderRadius: 6,
                            marginBottom: 16,
                            breakInside: 'avoid'
                          }}
                        />
                      </div>
                    )
                  })}
                {!fetchingListProduct &&
                  listProduct.map((e, i) => {
                    return (
                      <ProductItem key={i} data={e} onClick={_onClickProduct} />
                    )
                  })}
              </div>
              <div className='row page mt-0'>
                <div className='col-xl-3 p-t20'>
                  <p className='page-text'>{`Showing ${listProduct.length ?? 0} from ${listProductPagination?.total ?? 0} data`}</p>
                </div>
                <PaginationBar
                  currentPage={listProductPagination?.page ?? 0}
                  pageCount={listProductPagination?.pageCount ?? 0}
                  pageSize={listProductPagination?.pageSize ?? 0}
                  totalCount={listProductPagination?.total ?? 0}
                  onClickPrev={_onClickPrev}
                  onClickPageNumber={_onClickPageNumber}
                  onClickNext={_onClickNext}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TABLET_LANDSCAPE = 1280
const FilterSection = memo(({ className }: { className?: string }) => {
  const dispatch = useAppDispatch()

  const { listTopicFilter } = useAppSelector(selectTopicState)
  const [searchParams, setSearchParams] = useSearchParams()
  const { width } = useWindowDimensions()

  const _onClickResetFilter = () => {
    setSearchParams((prev) => {
      prev.delete('topic')
      return prev
    })
  }

  useEffect(() => {
    dispatch(getListTopicFilterThunk({ page: 1, pageSize: 200 }))
  }, [])

  return (
    <div className={`fade-in ${className}`}>
      <div className='accordion accordion-filter'>
        <div className='accordion-item'>
          <button
            className='accordion-button collapsed'
            id='headingOne'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseOne'
            aria-expanded='false'
            aria-controls='collapseOne'
          >
            Chủ đề
          </button>
          <div
            id='collapseOne'
            className={`accordion-collapse collapse ${width > TABLET_LANDSCAPE ? 'show' : ''} accordion-body`}
            aria-labelledby='headingOne'
            data-bs-parent='#accordionExample'
          >
            <div className='widget dz-widget_services'>
              {listTopicFilter.map((topic, index) => (
                <CheckBoxItem key={index} data={topic} searchParamKey='topic' />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='row filter-buttons'>
        <div>
          <a
            onClick={_onClickResetFilter}
            className='btn btn-outline-secondary btnhover mt-3 d-block'
          >
            Đặt lại
          </a>
        </div>
      </div>
    </div>
  )
})
