import { ROUTER, STORAGE_KEY } from '@/constants'
import { useAppDispatch, useAppSelector, usePrevious } from '@/hooks'
import { selectAuthState } from '@/redux/slices'
import { getMeThunk } from '@/redux/thunks'
import { memo, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

export const PrivateRoute = memo(() => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { fetchingGetMe, currentUser, errorGetMe } =
    useAppSelector(selectAuthState)

  const preFetchingGetMe = usePrevious(fetchingGetMe)
  const isLoggedIn = localStorage.getItem(STORAGE_KEY.IS_LOGGED_IN)

  useEffect(() => {
    if (isLoggedIn && !currentUser) dispatch(getMeThunk())
  }, [isLoggedIn, currentUser])

  useEffect(() => {
    if (preFetchingGetMe && !fetchingGetMe) {
      if (errorGetMe?.name === 'TokenExpiredError') {
        navigate(ROUTER.LOGIN, { replace: true })
      }
    }
  }, [preFetchingGetMe, errorGetMe])

  return <Outlet />
})
