import styles from '@/styles/components/CheckBoxItem.module.scss'
import { TTopic } from '@/types'
import { Checkbox, ConfigProvider } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { memo } from 'react'
import { useSearchParams } from 'react-router-dom'

type CheckBoxItemProps = {
  data?: TTopic
  searchParamKey?: string
}

export const CheckBoxItem = memo((props: CheckBoxItemProps) => {
  const { data, searchParamKey } = props
  const [searchParams, setSearchParams] = useSearchParams()

  const _listTopicId = searchParams.get(searchParamKey ?? '')?.split(',')
  const _isActive = _listTopicId?.includes(data?.id.toString() ?? '')

  const _onChange = (e: CheckboxChangeEvent) => {
    if (searchParamKey) {
      if (!_listTopicId) {
        return setSearchParams((prev) => {
          prev.set(searchParamKey, [data?.id].toString())
          prev.set('page', '1')
          return prev
        })
      }

      if (!_isActive) {
        return setSearchParams((prev) => {
          prev.set(searchParamKey, [..._listTopicId, data?.id].toString())
          prev.set('page', '1')
          return prev
        })
      }

      const _listId = _listTopicId?.filter((topicId) => {
        if (topicId !== data?.id.toString()) return topicId
        return undefined
      })

      if (_listId.length)
        return setSearchParams((prev) => {
          prev.set(searchParamKey, _listId?.toString() ?? '')
          prev.set('page', '1')
          return prev
        })

      return setSearchParams((prev) => {
        prev.delete(searchParamKey)
        prev.set('page', '1')
        return prev
      })
    }
  }

  return (
    <div
      key={data?.id}
      className={`form-check search-content ${styles.container}`}
    >
      <ConfigProvider theme={{ token: { colorPrimary: '#eaa451' } }}>
        <Checkbox checked={_isActive} onChange={_onChange}>
          <span
            className='form-check-label'
            style={{ fontSize: 16, color: 'rgb(119, 119, 119)' }}
          >
            {data?.title}
          </span>
        </Checkbox>
      </ConfigProvider>
    </div>
  )
})
