import { GoogleSignInButton } from '@/components'
import { ROUTER, STORAGE_KEY } from '@/constants'
import { useAppDispatch, useAppSelector, usePrevious } from '@/hooks'
import { selectAuthState } from '@/redux/slices'
import { signInThunk } from '@/redux/thunks'
import { Form, FormProps, Input, Modal, notification } from 'antd'
import {
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

type TAuthModalContext = {
  isLoggedIn: boolean
  openAuthModal: () => void
  hideAuthModal: () => void
}

type FieldType = {
  identifier?: string
  password?: string
}

export const AuthModalContext = createContext<TAuthModalContext>(
  {} as TAuthModalContext
)

export const useAuthContext = () => useContext(AuthModalContext)
export const AuthModalProvider = memo((props: PropsWithChildren) => {
  const _dispatch = useAppDispatch()
  const { fetchingSignIn, errorSignIn } = useAppSelector(selectAuthState)

  const [api, contextHolder] = notification.useNotification()
  const [isVisible, setIsVisible] = useState(false)

  const _preFetchingSignIn = usePrevious(fetchingSignIn)
  const _isLoggedIn = localStorage.getItem(STORAGE_KEY.IS_LOGGED_IN)

  const _openModal = useCallback(() => setIsVisible(true), [isVisible])
  const _hideModal = useCallback(() => setIsVisible(false), [isVisible])

  const _onFinish: FormProps<FieldType>['onFinish'] = (data) => {
    if (data.identifier && data.password) {
      const { identifier, password } = data
      _dispatch(signInThunk({ identifier, password }))
    }
  }

  useEffect(() => {
    if (_preFetchingSignIn && !fetchingSignIn) {
      if (!errorSignIn) {
        api.success({ message: 'Đăng nhập thành công' })
        return _hideModal()
      }
      // api.error({ message: errorSignIn.message })
      api.error({ message: 'Đăng nhập thất bại' })
    }
  }, [fetchingSignIn, errorSignIn])

  const _contextValue: TAuthModalContext = {
    isLoggedIn: Boolean(_isLoggedIn),
    openAuthModal: _openModal,
    hideAuthModal: _hideModal
  }

  return (
    <AuthModalContext.Provider value={_contextValue}>
      {contextHolder}
      {props.children}
      <Modal
        centered
        open={isVisible}
        destroyOnClose
        footer={null}
        onCancel={_hideModal}
        maskClosable={false}
        styles={{ content: { padding: 0 } }}
      >
        <div className='page-content'>
          <section className='shop-account'>
            <div className='login-area'>
              <div className='tab-content nav'>
                <Form
                  id='login'
                  onFinish={_onFinish}
                  className='tab-pane active col-12'
                  autoComplete='off'
                >
                  <h4 className='text-secondary'>Đăng nhập</h4>
                  <p className='font-weight-600'>
                    Nếu bạn có tài khoản, vui lòng đăng nhập.
                  </p>
                  <label className='label-title'>Tên đăng nhập *</label>
                  <Form.Item<FieldType> name='identifier'>
                    <Input
                      name='dzName'
                      required={true}
                      className='form-control'
                      placeholder='Tên đăng nhập'
                    />
                  </Form.Item>
                  <label className='label-title'>Mật khẩu *</label>
                  <Form.Item<FieldType> name='password'>
                    <Input
                      name='dzName'
                      required={true}
                      autoComplete='off'
                      className='form-control'
                      placeholder='Mật khẩu'
                      type='password'
                    />
                  </Form.Item>
                  <Form.Item
                    className='d-flex justify-content-center w-100'
                    style={{ marginBottom: 0 }}
                  >
                    <button
                      type='submit'
                      className='btn btn-primary btnhover me-2 align-self-center'
                    >
                      Đăng nhập
                    </button>
                  </Form.Item>
                  <div className='d-flex w-100 flex-column'>
                    <p
                      className='align-self-center my-3'
                      style={{
                        fontSize: 10,
                        color: '#00000066',
                        fontWeight: 'bolder'
                      }}
                    >
                      Hoặc
                    </p>
                    <div className='d-flex gap-3 align-self-center'>
                      <GoogleSignInButton />
                    </div>
                  </div>
                  <p
                    className='font-weight-600 mt-3'
                    style={{ textAlign: 'center' }}
                  >
                    Bạn chưa có tài khoản?{' '}
                    <a href={ROUTER.REGISTER}>Đăng kí tài khoản</a>
                  </p>
                </Form>
              </div>
            </div>
          </section>
        </div>
      </Modal>
    </AuthModalContext.Provider>
  )
})
