import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import { AuthModalProvider } from './contexts'
import './index.css'
import './styles/common/common.css'
import './styles/common/fonts.css'
import { store } from './redux'
import reportWebVitals from './reportWebVitals'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ConfigProvider } from 'antd'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
    <ConfigProvider theme={{ token: { fontFamily: 'FZ-Poppins-Regular' } }}>
      <React.StrictMode>
        <Provider store={store}>
          <AuthModalProvider>
            <App />
          </AuthModalProvider>
        </Provider>
      </React.StrictMode>
    </ConfigProvider>
  </GoogleOAuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
