import { PaginationBar, PublicTimeSelect, TopicItem } from '@/components'
import { DEFAULT_PRODUCT_PAGES_SIZE, ROUTER } from '@/constants'
import {
  useAppDispatch,
  useAppSelector,
  usePrevious,
  useWindowDimensions
} from '@/hooks'
import { selectTopicState } from '@/redux/slices'
import { getListTopicThunk } from '@/redux/thunks'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const ListTopicPageView = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    listTopic,
    listTopicPagination,
    fetchingListTopic,
    errorFetchListTopic
  } = useAppSelector(selectTopicState)

  const [searchParams, setSearchParams] = useSearchParams()
  const { width } = useWindowDimensions()

  const _prevFetchingListTopic = usePrevious(fetchingListTopic)

  const _onClickTopic = useCallback((topicId: number) => {
    navigate({
      pathname: ROUTER.PRODUCTS,
      search: `topic=${topicId}&page=1&pageSize=${DEFAULT_PRODUCT_PAGES_SIZE}&sort=createdAt:desc`
    })
  }, [])

  const _onClickPrev = useCallback(() => {
    const _prevPage = (listTopicPagination?.page ?? 0) - 1
    setSearchParams((searchParams) => {
      searchParams.set('page', _prevPage.toString())
      return searchParams
    })
  }, [listTopicPagination?.page])

  const _onClickPageNumber = useCallback((page: number) => {
    setSearchParams((searchParams) => {
      searchParams.set('page', page.toString())
      return searchParams
    })
  }, [])

  const _onClickNext = useCallback(() => {
    const _nextPage = (listTopicPagination?.page ?? 0) + 1
    setSearchParams((searchParams) => {
      searchParams.set('page', _nextPage.toString())
      return searchParams
    })
  }, [listTopicPagination?.page])

  useEffect(() => {
    if (_prevFetchingListTopic && !fetchingListTopic) {
      if (!errorFetchListTopic) {
        window.scrollTo(0, 0)
      }
    }
  }, [_prevFetchingListTopic, errorFetchListTopic])

  useEffect(() => {
    dispatch(
      getListTopicThunk({
        page: Number(searchParams.get('page')),
        pageSize: Number(searchParams.get('pageSize')),
        sort: searchParams.get('sort') ?? 'createdAt:desc'
      })
    )
  }, [searchParams])

  return (
    <div className='page-content bg-grey'>
      <div className='content-inner border-bottom'>
        <div className='container' style={{ maxWidth: width - 80 }}>
          <div className='row'>
            {/* <div className='col-xl-3'>
              <div className='shop-filter'>
                <div className='d-flex justify-content-between'>
                  <h4 className='title'>Bộ lọc</h4>
                  <a href='javascript:void(0);' className='panel-close-btn'>
                    <i className='flaticon-close'></i>
                  </a>
                </div>
                <div
                  className='accordion accordion-filter'
                  id='accordionExample'
                >
                  <div className='accordion-item'>
                    <button
                      className='accordion-button'
                      id='headingOne'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      Năm
                    </button>
                    <div
                      id='collapseOne'
                      className='accordion-collapse collapse show accordion-body'
                      aria-labelledby='headingOne'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='widget dz-widget_services d-flex justify-content-between'>
                        <div className=''>
                          <div className='form-check search-content'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='productCheckBox-01'
                            />
                            <label
                              className='form-check-label'
                              htmlFor='productCheckBox-01'
                            >
                              2024
                            </label>
                          </div>
                          <div className='form-check search-content'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='productCheckBox-02'
                            />
                            <label
                              className='form-check-label'
                              htmlFor='productCheckBox-02'
                            >
                              2023
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row filter-buttons'>
                  <div>
                    <a
                      href='javascript:void(0);'
                      className='btn btn-secondary btnhover mt-4 d-block'
                    >
                      Refine Search
                    </a>
                    <a
                      href='javascript:void(0);'
                      className='btn btn-outline-secondary btnhover mt-3 d-block'
                    >
                      Đặt lại bộ lọc
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div>
              {/** className='col-xl-9' */}
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='title'>Chủ đề</h4>
                {/* <a
                  href='javascript:void(0);'
                  className='btn btn-primary panel-btn'
                >
                  Lọc
                </a> */}
              </div>
              <div className='filter-area m-b30'>
                <div className='grid-area'></div>
                <div className='category py-2'>
                  {/* <div className='filter-category'>
                    <a
                      data-bs-toggle='collapse'
                      href='#collapseExample'
                      role='button'
                      aria-expanded='false'
                      aria-controls='collapseExample'
                    >
                      <i className='fas fa-list me-2'></i>
                      Categories
                    </a>
                  </div> */}
                  <PublicTimeSelect
                    defaultValue={'createdAt:desc'}
                    options={[
                      { value: 'createdAt:desc', label: 'Mới nhất' },
                      { value: 'createdAt:asc', label: 'Cũ nhất' },
                      { value: 'title:asc', label: 'A-Z' },
                      { value: 'title:desc', label: 'Z-A' }
                    ]}
                    onChange={(value) => {
                      setSearchParams((searchParams) => {
                        searchParams.set('sort', value)
                        return searchParams
                      })
                    }}
                  />
                </div>
              </div>
              {/* <div className='acod-content collapse' id='collapseExample'>
                <div className='widget widget_services style-2'>
                  <div className='form-check search-content'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='productCheckBox01'
                    />
                    <label
                      className='form-check-label'
                      htmlFor='productCheckBox01'
                    >
                      Architecture
                    </label>
                  </div>
                  <div className='form-check search-content'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='productCheckBox02'
                    />
                    <label
                      className='form-check-label'
                      htmlFor='productCheckBox02'
                    >
                      Art
                    </label>
                  </div>
                </div>
              </div> */}

              <div className='row book-grid-row'>
                {fetchingListTopic &&
                  Array.from(Array(9).keys()).map((e) => {
                    return (
                      <div key={e} className='col-book style-2'>
                        <div
                          className='skeleton'
                          style={{ aspectRatio: 16 / 9, borderRadius: 6 }}
                        />
                      </div>
                    )
                  })}
                {!fetchingListTopic &&
                  listTopic.map((e) => {
                    return (
                      <TopicItem key={e.id} data={e} onClick={_onClickTopic} />
                    )
                  })}
              </div>
              <div className='row page mt-0'>
                <div className='col-xl-3 p-t20'>
                  <p className='page-text'>{`Showing ${listTopic.length ?? 0} from ${listTopicPagination?.total ?? 0} data`}</p>
                </div>
                <PaginationBar
                  currentPage={listTopicPagination?.page ?? 0}
                  pageCount={listTopicPagination?.pageCount ?? 0}
                  pageSize={listTopicPagination?.pageSize ?? 0}
                  totalCount={listTopicPagination?.total ?? 0}
                  onClickPrev={_onClickPrev}
                  onClickPageNumber={_onClickPageNumber}
                  onClickNext={_onClickNext}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListTopicPageView
