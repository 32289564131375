import defaultTopic from '@/assets/images/defaultTopic.jpg'
import styles from '@/styles/components/TopicItem.module.scss'
import { TTopic } from '@/types'

type TopicItemProps = {
  data: TTopic
  onClick?: (topicId: number) => void
}

export const TopicItem = (props: TopicItemProps) => {
  const { data, onClick } = props
  return (
    <div
      onClick={() => onClick && onClick(data.id)}
      className='fade-in-3s col-book style-2'
    >
      <div className={styles.container}>
        <img
          alt='img1'
          loading='lazy'
          src={data.coverImage?.url ?? defaultTopic}
          className={styles.thumbnail}
        />
        <div className={styles.overlay}>
          <h5 className={styles.topicName}>{data.title}</h5>
        </div>
      </div>
    </div>
  )
}
