import { TError, TSearchState } from '@/types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'
import { getSearchSuggestionThunk, getSearchThunk } from '../thunks'

const initialState: TSearchState = {
  products: [],
  topics: [],

  listSuggestedProduct: [],
  listTrendingKeyword: []
}

export const SearchSlice = createSlice({
  name: 'SearchSlice',
  initialState,
  reducers: {
    resetSearchResult: (state) => {
      state.products = []
      state.topics = []
    }
  },
  extraReducers: (builder) => {
    /** GET SEARCH */
    builder.addCase(getSearchThunk.pending, (state) => {
      state.fetchingSearch = true
    })
    builder.addCase(getSearchThunk.fulfilled, (state, action) => {
      state.products = action.payload.products
      state.topics = action.payload.topics

      state.fetchingSearch = false
      state.errorFetchSearch = undefined
    })
    builder.addCase(getSearchThunk.rejected, (state, action) => {
      state.fetchingSearch = false
      state.errorFetchSearch = action.payload as TError
    })

    /** GET SEARCH SUGGESTION */
    builder.addCase(getSearchSuggestionThunk.pending, (state) => {
      state.fetchingSearchSuggestion = true
    })
    builder.addCase(getSearchSuggestionThunk.fulfilled, (state, action) => {
      state.listSuggestedProduct = action.payload.products
      state.listTrendingKeyword = action.payload.keywords
      state.fetchingSearchSuggestion = false
      state.errorFetchSearchSuggestion = undefined
    })
    builder.addCase(getSearchSuggestionThunk.rejected, (state, action) => {
      state.fetchingSearchSuggestion = false
      state.errorFetchSearchSuggestion = action.payload as TError
    })
  }
})

export const searchActions = SearchSlice.actions
export const selectSearchState = (state: RootState) => state.searchState

export default SearchSlice.reducer
