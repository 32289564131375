import footer from '@/assets/footer.jpg'
import { ReactComponent as QuantityIcon } from '@/assets/icons/quality.svg'
import { ReactComponent as SecurityIcon } from '@/assets/icons/security.svg'
import { ReactComponent as TimeIcon } from '@/assets/icons/time.svg'
import defaultImage from '@/assets/images/defaultTopic.jpg'
import { CarouselWrapper } from '@/components'
import { DEFAULT_PRODUCT_PAGES_SIZE, ROUTER } from '@/constants'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { selectHomeConfigState } from '@/redux/slices'
import { getHomeConfigThunk } from '@/redux/thunks'
import { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const BANNER_SLIDE_TO_SHOW = 1

const HomeView = () => {
  const _navigate = useNavigate()
  const _dispatch = useAppDispatch()
  const { favoredProducts, suggestionProducts, highlightTopics, slideShow } =
    useAppSelector(selectHomeConfigState)

  const _onClickProduct = (productId: number, listTopicId?: number[]) => {
    _navigate({
      pathname: ROUTER.PRODUCT_DETAIL,
      search: `id=${productId}&topic=${listTopicId?.toString()}`
    })
  }

  const _onClickTopic = (topicId: number) => {
    _navigate({
      pathname: ROUTER.PRODUCTS,
      search: `topic=${topicId}&page=1&pageSize=${DEFAULT_PRODUCT_PAGES_SIZE}&sort=createdAt:desc`
    })
  }

  useLayoutEffect(() => {
    _dispatch(getHomeConfigThunk())
  }, [])

  return (
    <div className='page-content bg-white'>
      {/* <div className='container'> */}
      {slideShow.length ? (
        <CarouselWrapper
          swipe={slideShow.length > BANNER_SLIDE_TO_SHOW}
          infinite={slideShow.length > BANNER_SLIDE_TO_SHOW}
          containerClassName='m-0'
          arrows={false}
          slidesToShow={BANNER_SLIDE_TO_SHOW}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]}
        >
          {slideShow.map((e, i) => {
            return (
              <a href={e.link} target='_blank' rel='noreferrer'>
                <img
                  key={i}
                  src={e.image?.url}
                  alt='banner'
                  className='fade-in-3s'
                  style={{ width: '100%', height: '100%' }}
                />
              </a>
            )
          })}
        </CarouselWrapper>
      ) : undefined}
      {/* </div> */}

      {/* Feature Product */}
      {/* <section className='content-inner-1 bg-grey reccomend'>
        <div className='container'>
          <div className='section-head text-center'>
            <div className='circle style-1'></div>
            <h3 className='title'>Thiết kế nổi bật</h3>
          </div>
        </div>
        <div className='container' style={{ padding: '0px 60px' }}>
          {outstandingList.map((product, i) => {
            const _onClickDownLoad = () => {
              if (isLoggedIn) return console.log('ready to download')
              return openAuthModal()
            }

            const _goToDetail = () => {
              _navigate({
                pathname: ROUTER.PRODUCT_DETAIL,
                search: `id=${product.id}`
              })
            }

            return (
              <div key={i} className='books-card style-2'>
                <div
                  className='dz-media'
                  onClick={() => _onClickProduct(product.id)}
                >
                  <img
                    style={{ objectFit: 'cover', width: 500 }}
                    src={product.coverImage}
                    alt='book'
                  />
                </div>
                <div className='dz-content'>
                  <h2
                    className='title'
                    onClick={() => _onClickProduct(product.id)}
                    style={{ fontSize: '2.5rem' }}
                  >
                    {product.name}
                  </h2>
                  <ul className='dz-tags'>
                    {product.topics?.map((e, i) => {
                      return <li key={i}>{e.title}</li>
                    })}
                  </ul>
                  <p className='text'>{product.description}</p>
                  <div className='price'>
                    <span className='price-num'>${listProduct[0]?.price}</span>
                    <del>$12.0</del>
                    <span className='badge'>20% OFF</span>
                  </div>
                  <div className='bookcard-footer'>
                    <a
                      onClick={_onClickDownLoad}
                      href='javascript:void(0);'
                      className='btn btn-primary btnhover m-t15 m-r10'
                    >
                      Mua ngay
                    </a>
                    <a
                      onClick={_goToDetail}
                      href='javascript:void(0);'
                      className='btn btn-outline-secondary btnhover m-t15'
                    >
                      Chi tiết
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section> */}
      {/* Feature Product End */}

      {/*Recommend Section Start*/}
      {suggestionProducts.length ? (
        <section className='content-inner-1 bg-grey reccomend'>
          <div className='container'>
            <div className='section-head text-center'>
              <h2 className='title'>Đề xuất dành cho bạn</h2>
            </div>
            <CarouselWrapper>
              {suggestionProducts.map((product, i) => {
                return (
                  <div key={i}>
                    <div
                      className='books-card style-1 wow fadeInUp'
                      style={{
                        marginLeft: 8,
                        marginRight: 8,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      data-wow-delay={`0.${i + 1}s`}
                    >
                      <div
                        className='dz-media'
                        onClick={() => {
                          _onClickProduct(
                            product.id,
                            product.topics?.map((e) => e.id)
                          )
                        }}
                      >
                        <img
                          style={{
                            objectFit: 'cover',
                            aspectRatio: 16 / 9,
                            width: '100%',
                            cursor: 'pointer'
                          }}
                          src={product.coverImage?.url || defaultImage}
                          alt='book'
                        />
                      </div>
                      {/* <div
                      className='dz-content'
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <h5
                        className='title'
                        onClick={() => {
                          _onClickProduct(
                            product.id,
                            product.topics?.map((e) => e.id)
                          )
                        }}
                      >
                        {product.name}
                      </h5>
                    </div> */}
                    </div>
                  </div>
                )
              })}
            </CarouselWrapper>
          </div>
        </section>
      ) : undefined}
      {/*Recommend Section End*/}

      {/* icon-box1 */}
      <section className='content-inner-2'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-4 col-sm-6 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              <div className='icon-bx-wraper style-1 m-b30 text-center'>
                <div className='icon-bx-sm m-b10'>
                  <TimeIcon />
                </div>
                <div className='icon-content'>
                  <h5 className='dz-title m-b10' style={{ color: '#eb3c23' }}>
                    Bàn giao nhanh chóng
                  </h5>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-sm-6 wow fadeInUp'
              data-wow-delay='0.2s'
            >
              <div className='icon-bx-wraper style-1 m-b30 text-center'>
                <div className='icon-bx-sm m-b10'>
                  <SecurityIcon />
                </div>
                <div className='icon-content'>
                  <h5 className='dz-title m-b10' style={{ color: '#eb3c23' }}>
                    Thanh toán bảo mật
                  </h5>
                  <p>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor */}
                  </p>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-sm-6 wow fadeInUp'
              data-wow-delay='0.3s'
            >
              <div className='icon-bx-wraper style-1 m-b30 text-center'>
                <div className='icon-bx-sm m-b10'>
                  <QuantityIcon />
                </div>
                <div className='icon-content'>
                  <h5 className='dz-title m-b10' style={{ color: '#eb3c23' }}>
                    Đảm bảo chất lượng
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* icon-box1 End*/}

      {/* Book Sale */}
      {highlightTopics.length ? (
        <section className='content-inner-1'>
          <div className='container'>
            <div className='section-head book-align'>
              <h2 className='title mb-0 w-100' style={{ textAlign: 'center' }}>
                Chủ đề nổi bật
              </h2>
            </div>
            <CarouselWrapper>
              {highlightTopics.map((topic, i) => {
                return (
                  <div key={i}>
                    <div
                      className='books-card style-3 wow fadeInUp'
                      style={{ marginLeft: 8, marginRight: 8 }}
                      data-wow-delay={`0.${i + 1}s`}
                    >
                      <div
                        onClick={() => _onClickTopic(topic.id)}
                        className='dz-media'
                      >
                        <img
                          style={{
                            objectFit: 'cover',
                            aspectRatio: 16 / 9,
                            cursor: 'pointer'
                          }}
                          loading='lazy'
                          src={topic?.coverImage?.url || defaultImage}
                          alt='book'
                        />
                      </div>
                      {/* <div className='dz-content'>
                      <h6 className='title' style={{ textAlign: 'center' }}>
                        <a
                          href='javascript:void(0);'
                          onClick={() => _onClickTopic(topic.id)}
                        >
                          {topic.title}
                        </a>
                      </h6>
                    </div> */}
                    </div>
                  </div>
                )
              })}
            </CarouselWrapper>
          </div>
        </section>
      ) : undefined}
      {/* Book Sale End */}

      {/* Special Offer*/}
      {favoredProducts.length ? (
        <section className='content-inner-1'>
          <div className='container'>
            <div className='section-head book-align'>
              <h3 className='title mb-0 w-100' style={{ textAlign: 'center' }}>
                Thiết kế được yêu thích nhất
              </h3>
            </div>
            <CarouselWrapper>
              {favoredProducts.map((product, i) => {
                return (
                  <div key={i}>
                    <div
                      id={i.toString()}
                      className='dz-card style-2 wow fadeInUp'
                      style={{ marginLeft: 8, marginRight: 8 }}
                      data-wow-delay={`0.${i + 1}s`}
                    >
                      <div
                        onClick={() => {
                          _onClickProduct(
                            product.id,
                            product.topics?.map((e) => e.id)
                          )
                        }}
                        className='dz-media'
                        style={{ borderRadius: '12px' }}
                      >
                        <a href='javascript:void(0);'>
                          <img
                            style={{
                              aspectRatio: 16 / 9,
                              objectFit: 'cover',
                              cursor: 'pointer'
                            }}
                            loading='lazy'
                            src={product.coverImage?.url || defaultImage}
                            alt='/'
                          />
                        </a>
                      </div>
                      {/* <div
                      className='dz-info'
                      style={{
                        padding: 12
                      }}
                    >
                      <h6 className='dz-title'>
                        <a
                          href='javascript:void(0);'
                          onClick={() => {
                            _onClickProduct(
                              product.id,
                              product.topics?.map((e) => e.id)
                            )
                          }}
                        >
                          {product.name}
                        </a>
                      </h6>
                      <div className='price-details'></div>
                    </div> */}
                    </div>
                  </div>
                )
              })}
            </CarouselWrapper>
          </div>
        </section>
      ) : undefined}
      {/* Special Offer End */}

      {/* Feature Box */}
      <section className='content-inner'>
        <div className='container'>
          <div className='row sp15'>
            <div
              className='col-lg-3 col-md-6 col-sm-6 col-6 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              <div className='icon-bx-wraper style-2 m-b30 text-center'>
                <div className='icon-bx-lg'>
                  <i className='fa-solid fa-users icon-cell'></i>
                </div>
                <div className='icon-content'>
                  <h2 className='dz-title counter m-b0'>+2000</h2>
                  <p className='font-20'>Khách hàng hài lòng</p>
                </div>
              </div>
            </div>
            <div
              className='col-lg-3 col-md-6 col-sm-6 col-6 wow fadeInUp'
              data-wow-delay='0.2s'
            >
              <div className='icon-bx-wraper style-2 m-b30 text-center'>
                <div className='icon-bx-lg'>
                  <i className='fa-solid fa-book icon-cell'></i>
                </div>
                <div className='icon-content'>
                  <h2 className='dz-title counter m-b0'>+2000</h2>
                  <p className='font-20'>Bản thiết kế</p>
                </div>
              </div>
            </div>
            <div
              className='col-lg-3 col-md-6 col-sm-6 col-6 wow fadeInUp'
              data-wow-delay='0.3s'
            >
              <div className='icon-bx-wraper style-2 m-b30 text-center'>
                <div className='icon-bx-lg'>
                  <i className='fa-solid fa-store icon-cell'></i>
                </div>
                <div className='icon-content'>
                  <h2 className='dz-title counter m-b0'>+110</h2>
                  <p className='font-20'>Chủ đề</p>
                </div>
              </div>
            </div>
            <div
              className='col-lg-3 col-md-6 col-sm-6 col-6 wow fadeInUp'
              data-wow-delay='0.4s'
            >
              <div className='icon-bx-wraper style-2 m-b30 text-center'>
                <div className='icon-bx-lg'>
                  <i className='fa-solid fa-leaf icon-cell'></i>
                </div>
                <div className='icon-content'>
                  <h2 className='dz-title counter m-b0'>+30</h2>
                  <p className='font-20'>Designers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature Box End */}

      {/* Newsletter */}
      <section
        className='py-4 px-6 newsletter-wrapper'
        style={{
          backgroundImage: `url(${footer})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='container'>
          <div className='subscride-inner'>
            <div className='row style-1 justify-content-xl-between justify-content-lg-center align-items-center text-xl-start text-center'>
              <div
                className='col-xl-6 col-lg-12 wow fadeInUp'
                data-wow-delay='0.1s'
              >
                <div className='section-head mb-0'>
                  <h2 className='title text-white my-lg-3 mt-0'>
                    Đăng ký để cập nhật các thiết kế mới nhất
                  </h2>
                </div>
              </div>
              <div
                className='col-xl-5 col-lg-6 wow fadeInUp'
                data-wow-delay='0.2s'
              >
                <form
                  className='dzSubscribe style-1'
                  action='script/mailchamp.php'
                  method='post'
                >
                  <div className='dzSubscribeMsg'></div>
                  <div className='form-group'>
                    <div className='input-group mb-0'>
                      <input
                        name='dzEmail'
                        required={true}
                        type='email'
                        className='form-control bg-transparent text-white'
                        placeholder='Địa chỉ email của bạn'
                      />
                      <div className='input-group-addon'>
                        <button
                          name='submit'
                          value='Submit'
                          type='submit'
                          className='btn btn-primary btnhover'
                        >
                          <span>Theo Dõi</span>
                          <i className='fa-solid fa-paper-plane'></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Newsletter End */}
    </div>
  )
}

export default HomeView
