import { submitRequestFormRequest } from '@/services'
import { TRequiredFormParams } from '@/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const submitRequiredFormThunk = createAsyncThunk(
  'RequiredFormAsyncThunk/submitRequiredFormThunk',
  async (
    payload: TRequiredFormParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { data, error } = await submitRequestFormRequest(payload)
    if (data) return fulfillWithValue(data)
    return rejectWithValue(error)
  }
)
