import { getWebConfigRequest } from '@/services'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getWebConfigThunk = createAsyncThunk(
  'WebConfigAsyncThunk/getWebConfigThunk',
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await getWebConfigRequest()
    if (data) return fulfillWithValue(data)
    return rejectWithValue(error)
  }
)
