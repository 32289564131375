import { useState } from 'react'

export const useModalHandler = <T>(initialValue?: T) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentValue, setCurrentValue] = useState(initialValue)

  const _show = (value?: T) => {
    value && setCurrentValue(value)
    setIsOpen(true)
  }

  const _hide = () => {
    setIsOpen(false)
    currentValue && setCurrentValue(undefined)
  }

  return {
    currentValue,
    open: isOpen,
    show: _show,
    hide: _hide
  }
}
