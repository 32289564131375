import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  usePrevious
} from '@/hooks'
import { selectRequiredFormState } from '@/redux/slices'
import { submitRequiredFormThunk } from '@/redux/thunks'
import styles from '@/styles/page/RequestServicePageView.module.scss'
import { TRequiredFormParams } from '@/types'
import { isEmailAddress, isVNPhoneNumber } from '@/utils'
import { Form, Input, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'

const RequestServicePageView = () => {
  const _dispatch = useAppDispatch()
  const { fetchingSubmitRequiredForm, errorSubmitRequiredForm } =
    useAppSelector(selectRequiredFormState)

  const [api, contextHolder] = notification.useNotification()

  const [isSubmitForm, setIsSubmitForm] = useState(false)
  const [requiredForm] = useForm()

  const _prevSubmitRequiredForm = usePrevious(fetchingSubmitRequiredForm)
  const _bounceSubmitAction = useDebounce(isSubmitForm, 500)

  const _validateNotEmpty = (name: string, errorMessage?: string) => {
    if (name) return Promise.resolve()
    return Promise.reject(errorMessage)
  }

  const _validateEmailAddress = async (address: string) => {
    await _validateNotEmpty(address, 'Vui lòng nhập địa chỉ email.')
    if (isEmailAddress(address)) return Promise.resolve()
    return Promise.reject('Địa chỉ email không hợp lệ.')
  }

  const _validateVNPhoneNumber = async (phone: string) => {
    await _validateNotEmpty(phone, 'Vui lòng nhập số điện thoại.')
    if (isVNPhoneNumber(phone)) return Promise.resolve()
    return Promise.reject('Số điện thoại không hợp lệ.')
  }

  useEffect(() => {
    if (_bounceSubmitAction) {
      const _formValues = requiredForm.getFieldsValue()
      _dispatch(submitRequiredFormThunk(_formValues))
    }
  }, [_bounceSubmitAction])

  useEffect(() => {
    if (_prevSubmitRequiredForm && !fetchingSubmitRequiredForm) {
      if (!errorSubmitRequiredForm) {
        api.success({
          message: 'Thành công',
          description: `Yêu cầu của bạn đã được gửi đi. SENCAM sẽ phản hồi về yêu cầu của bạn qua địa chỉ email.`
        })
        requiredForm.resetFields()
      }

      if (errorSubmitRequiredForm) {
        api.error({
          message: 'Không thành công',
          description: 'Vui lòng thử lại'
          // description: errorSubmitRequiredForm.message
        })
      }

      setIsSubmitForm(false)
    }
  }, [_prevSubmitRequiredForm, errorSubmitRequiredForm])

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={`col-lg-7 ${styles.subContainer}`}>
        <div className='contact-area1 m-md-r0'>
          <div className='section-head style-1'>
            <h6
              className='sub-title text-primary'
              style={{ textAlign: 'center' }}
            >
              Liên hệ với chúng tôi
            </h6>
          </div>
          <Form
            form={requiredForm}
            onFinish={() => setIsSubmitForm(true)}
            autoComplete='none'
            autoCorrect='none'
          >
            <Form.Item<TRequiredFormParams>
              required
              name='name'
              validateDebounce={500}
              rules={[
                {
                  message: 'Vui lòng nhập họ và tên.',
                  validator: (_, value) => _validateNotEmpty(value)
                }
              ]}
            >
              <Input
                type='text'
                autoComplete='off'
                autoCapitalize='none'
                autoCorrect='none'
                className='form-control'
                placeholder='Họ và Tên'
              />
            </Form.Item>

            <Form.Item<TRequiredFormParams>
              name='organization'
              required
              validateDebounce={500}
              rules={[
                {
                  message: 'Vui lòng nhập tên cơ quan/đơn vị.',
                  validator: (_, value) => _validateNotEmpty(value)
                }
              ]}
            >
              <Input
                type='text'
                autoComplete='off'
                autoCapitalize='none'
                autoCorrect='none'
                className='form-control'
                placeholder='Cơ quan/Đơn vị'
              />
            </Form.Item>

            <Form.Item<TRequiredFormParams>
              name='email'
              required
              validateDebounce={500}
              rules={[
                { validator: (_, value) => _validateEmailAddress(value) }
              ]}
            >
              <Input
                type='text'
                autoComplete='off'
                autoCapitalize='none'
                autoCorrect='none'
                className='form-control'
                placeholder='Địa chỉ email'
              />
            </Form.Item>

            <Form.Item<TRequiredFormParams>
              name='phone'
              validateDebounce={500}
              required
              rules={[
                { validator: (_, value) => _validateVNPhoneNumber(value) }
              ]}
            >
              <Input
                type='text'
                autoComplete='off'
                autoCapitalize='none'
                autoCorrect='none'
                className='form-control'
                placeholder='Số điện thoại'
              />
            </Form.Item>

            <Form.Item<TRequiredFormParams>
              name='description'
              required
              validateDebounce={500}
              rules={[
                {
                  message: 'Vui lòng nhập đầy đủ thông tin mô tả.',
                  validator: (_, value) => _validateNotEmpty(value)
                }
              ]}
            >
              <TextArea rows={5} placeholder='Mô tả' className='form-control' />
            </Form.Item>

            <div>
              <button type='submit' className='btn w-100 btn-primary btnhover'>
                Gửi
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default RequestServicePageView
