import { getHomeConfigRequest } from '@/services'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getHomeConfigThunk = createAsyncThunk(
  'HomeConfigAsyncThunk/getHomeConfigThunk',
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await getHomeConfigRequest()
    if (data) return fulfillWithValue(data)
    return rejectWithValue(error)
  }
)
