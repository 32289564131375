import { getSearchRequest, getSearchSuggestionRequest } from '@/services'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getSearchThunk = createAsyncThunk(
  'SearchAsyncThunk/getSearchThunk',
  async (payload: { key?: string }, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await getSearchRequest(payload.key)
    if (data) return fulfillWithValue(data)
    return rejectWithValue(error)
  }
)

export const getSearchSuggestionThunk = createAsyncThunk(
  'SearchAsyncThunk/getSearchSuggestionThunk',
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await getSearchSuggestionRequest()
    if (data) return fulfillWithValue(data)
    return rejectWithValue(error)
  }
)
