import { TError, TNetworkResponse, TResponse, TWebConfig } from '@/types'
import { TWebConfigDTO } from '@/types/dto'
import { mapSnakeCaseToCamelCase } from '@/utils'
import { axiosClient } from './Axios'

export const getWebConfigRequest = async (): Promise<TResponse<TWebConfig>> => {
  try {
    const { data } = await axiosClient.get<
      TNetworkResponse<TWebConfigDTO>,
      any
    >({
      path: 'web-config'
    })

    return { data: mapSnakeCaseToCamelCase<TWebConfig>(data.data) }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}
