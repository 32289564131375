import {
  TError,
  TNetworkResponse,
  TRequiredForm,
  TRequiredFormParams,
  TResponse
} from '@/types'
import { RequiredFormDTO } from '@/types/dto'
import { axiosClient } from './Axios'

export const submitRequestFormRequest = async (
  payload: TRequiredFormParams
): Promise<TResponse<TRequiredForm>> => {
  try {
    const { data } = await axiosClient.post<
      TNetworkResponse<RequiredFormDTO>,
      { data: TRequiredFormParams }
    >({
      path: 'required-forms',
      data: { data: payload }
    })

    return { data: data.data }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}
