import { ReactComponent as FilterIcon } from '@/assets/icons/filterIcon.svg'
import { ProductItem, PublicTimeSelect } from '@/components'
import { ROUTER } from '@/constants'
import {
  useAppDispatch,
  useAppSelector,
  usePrevious,
  useWindowDimensions
} from '@/hooks'
import { selectSearchState, selectTopicState } from '@/redux/slices'
import { getListTopicFilterThunk, getSearchThunk } from '@/redux/thunks'
import styles from '@/styles/page/ListProductPageView.module.scss'
import { ConfigProvider, Radio, Space } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const SearchPageView = () => {
  const _dispatch = useAppDispatch()
  const _navigate = useNavigate()

  const { listTopicFilter } = useAppSelector(selectTopicState)
  const { products, fetchingSearch, errorFetchSearch } =
    useAppSelector(selectSearchState)

  const [isOpenFilter, setIsOpenFilter] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterTopic, setFilterTopic] = useState<number>()
  const { width } = useWindowDimensions()

  const _preFetchingSearch = usePrevious(fetchingSearch)

  const _displayProducts = useMemo(() => {
    if (filterTopic) {
      return products.filter((e) => e.topics.some((e) => e.id === filterTopic))
    }
    return products
  }, [filterTopic, products])

  const _toggleFilter = () => setIsOpenFilter((prev) => !prev)

  const _onClickProduct = useCallback(
    (productId: number, listTopicId?: number[]) => {
      if (listTopicId?.length) {
        return _navigate({
          pathname: ROUTER.PRODUCT_DETAIL,
          search: `id=${productId}&topic=${listTopicId?.toString()}`
        })
      }
      return _navigate({
        pathname: ROUTER.PRODUCT_DETAIL,
        search: `id=${productId}`
      })
    },
    []
  )

  const _onClickResetFilter = () => setFilterTopic(undefined)

  useEffect(() => {
    if (_preFetchingSearch && !fetchingSearch) {
      if (!errorFetchSearch) {
        window.scrollTo(0, 0)
      }
    }
  }, [_preFetchingSearch, errorFetchSearch])

  useEffect(() => {
    _dispatch(getListTopicFilterThunk({ page: 1, pageSize: 200 }))
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    const _searchKey = searchParams.get('key')
    if (_searchKey && _searchKey.length >= 3) {
      _onClickResetFilter()
      _dispatch(getSearchThunk({ key: _searchKey }))
    }
  }, [searchParams.get('key')])

  return (
    <div className='page-content bg-grey'>
      <div className='content-inner border-bottom'>
        <div className='container' style={{ maxWidth: width - 80 }}>
          <div className={`d-flex gap-4 flex-wrap ${styles.contentContainer}`}>
            {isOpenFilter && (
              <div style={{ flex: 1 }}>
                <div className={styles.filterButtonWrapper}>
                  <h4 className='title'>Bộ lọc</h4>
                  <a onClick={_toggleFilter} className={styles.filterButton}>
                    <FilterIcon />
                  </a>
                </div>
                <div className={styles.filterContainer1}>
                  <div className='accordion accordion-filter'>
                    <div className='accordion-item'>
                      <button
                        className='accordion-button collapsed'
                        id='headingOne'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseOne'
                        aria-expanded='false'
                        aria-controls='collapseOne'
                      >
                        Chủ đề
                      </button>
                      <div
                        id='collapseOne'
                        className='accordion-collapse collapse show accordion-body'
                        aria-labelledby='headingOne'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='widget dz-widget_services'>
                          <ConfigProvider
                            theme={{ token: { colorPrimary: '#eaa451' } }}
                          >
                            <Radio.Group
                              value={filterTopic}
                              onChange={({ target }) => {
                                setFilterTopic(target.value)
                              }}
                            >
                              <Space direction='vertical'>
                                {listTopicFilter.map((topic, index) => (
                                  <Radio
                                    key={index}
                                    value={topic.id}
                                    className='form-check-label'
                                    style={{
                                      fontSize: 16,
                                      color: 'rgb(119, 119, 119)'
                                    }}
                                  >
                                    {topic.title}
                                  </Radio>
                                ))}
                              </Space>
                            </Radio.Group>
                          </ConfigProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row filter-buttons'>
                    <div>
                      <a
                        onClick={_onClickResetFilter}
                        className='btn btn-outline-secondary btnhover mt-3 d-block'
                      >
                        Đặt lại
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div style={{ flex: 4 }}>
              <div className='d-flex gap-3'>
                {!isOpenFilter && (
                  <a
                    onClick={_toggleFilter}
                    className={`${styles.filterButton} ${styles.filterButtonCollapse}`}
                  >
                    <FilterIcon style={{ transform: 'rotate(180deg)' }} />
                  </a>
                )}
                <h4 className='title'>{`Tìm kiếm "${searchParams.get('key')}"`}</h4>
              </div>
              {isOpenFilter && (
                <div className={styles.filterContainer2}>
                  <div className='accordion accordion-filter'>
                    <div className='accordion-item'>
                      <button
                        className='accordion-button collapsed'
                        id='headingOne'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseOne'
                        aria-expanded='false'
                        aria-controls='collapseOne'
                      >
                        Chủ đề
                      </button>
                      <div
                        id='collapseOne'
                        className='accordion-collapse collapse show accordion-body'
                        aria-labelledby='headingOne'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='widget dz-widget_services'>
                          <ConfigProvider
                            theme={{ token: { colorPrimary: '#eaa451' } }}
                          >
                            <Radio.Group
                              value={filterTopic}
                              onChange={({ target }) => {
                                setFilterTopic(target.value)
                              }}
                            >
                              <Space direction='vertical'>
                                {listTopicFilter.map((topic, index) => (
                                  <Radio
                                    key={index}
                                    value={topic.id}
                                    className='form-check-label'
                                    style={{
                                      fontSize: 16,
                                      color: 'rgb(119, 119, 119)'
                                    }}
                                  >
                                    {topic.title}
                                  </Radio>
                                ))}
                              </Space>
                            </Radio.Group>
                          </ConfigProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row filter-buttons'>
                    <div>
                      <a
                        onClick={_onClickResetFilter}
                        className='btn btn-outline-secondary btnhover mt-3 d-block'
                      >
                        Đặt lại
                      </a>
                    </div>
                  </div>
                </div>
              )}
              <div className='filter-area m-b30'>
                <div className='grid-area'></div>
                <div className='category py-2'>
                  <PublicTimeSelect
                    defaultValue={'createdAt:desc'}
                    options={[
                      { value: 'createdAt:desc', label: 'Mới nhất' },
                      { value: 'createdAt:asc', label: 'Cũ nhất' },
                      { value: 'name:asc', label: 'A-Z' },
                      { value: 'name:desc', label: 'Z-A' }
                    ]}
                    onChange={(value) => {
                      setSearchParams((searchParams) => {
                        searchParams.set('sort', value)
                        return searchParams
                      })
                    }}
                  />
                </div>
              </div>

              <div className={styles.productGrid}>
                {fetchingSearch &&
                  Array.from(Array(12).keys()).map((e) => {
                    return (
                      <div key={e} className='col-book style-2'>
                        <div
                          className='skeleton'
                          style={{
                            // aspectRatio: 16 / 9,
                            height: Math.floor(
                              Math.random() * (200 - 150) + 150
                            ),
                            borderRadius: 6,
                            marginBottom: 16,
                            breakInside: 'avoid'
                          }}
                        />
                      </div>
                    )
                  })}
                {!fetchingSearch &&
                  _displayProducts.map((e, i) => {
                    return (
                      <ProductItem key={i} data={e} onClick={_onClickProduct} />
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchPageView
