export const isVNPhoneNumber = (phone: string) => {
  const _regex = new RegExp(
    /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/
  )
  return _regex.test(phone)
}

export const isEmailAddress = (address: string) => {
  const _regex = new RegExp(
    /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm
  )
  return _regex.test(address)
}
