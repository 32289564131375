import { useEffect, useState } from 'react'

export const useDebounce = <T>(value: T, delay?: number) => {
  const [bouncedValue, setBouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      setBouncedValue(value)
    }, delay ?? 500)

    return () => clearTimeout(timer)
  }, [value, delay])

  return bouncedValue
}
