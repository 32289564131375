export enum ROUTER {
  LOGIN = '/login',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgot-password',

  HOME = '/',
  USER_PROFILE = '/profile',
  CART = '/cart',
  SUBJECT = '/subject',
  PRODUCTS = '/products',
  PRODUCT_DETAIL = '/product',
  CONTACT_US = '/contact-us',
  TOPIC = '/topics',
  PRIVACY_POLICY = '/privacy-policy',
  CUSTOMER_COMPLAINT_POLICY = '/customer-complaint-policy',
  REQUEST_SERVICE = '/request-service',
  SALES_POLICIES = '/sales-policies',
  SEARCH = '/search',

  NOT_FOUND = '*'
}
