import { ReactComponent as MagnifyingGlass } from '@/assets/icons/magnifyingGlass.svg'
import { ReactComponent as XSign } from '@/assets/icons/xSign.svg'
import { ModalWrapper } from '@/components'
import { DEFAULT_PRODUCT_PAGES_SIZE, ROUTER } from '@/constants'
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useModalHandler,
  usePrevious
} from '@/hooks'
import { searchActions, selectSearchState } from '@/redux/slices'
import { getSearchSuggestionThunk, getSearchThunk } from '@/redux/thunks'
import { submitSearchCountRequest } from '@/services'
import styles from '@/styles/page/SearchModalView.module.scss'
import { LoadingOutlined } from '@ant-design/icons'
import { Input, InputRef, Spin } from 'antd'
import {
  Fragment,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'

type SearchPageViewProps = {
  modalHandler: ReturnType<typeof useModalHandler>
}

const SearchModalView = memo((props: SearchPageViewProps) => {
  const { modalHandler } = props

  const _dispatch = useAppDispatch()
  const _navigate = useNavigate()

  const {
    topics,
    products,
    fetchingSearch,
    listTrendingKeyword,
    listSuggestedProduct
  } = useAppSelector(selectSearchState)

  const [searchKey, setSearchKey] = useState('')

  const _prevFetchingSearch = usePrevious(fetchingSearch)
  const _searchInputRef = useRef<InputRef>(null)
  const _bounceSearchKey = useDebounce(searchKey)

  const _resetSearchResult = () => {
    setSearchKey('')
    _dispatch(searchActions.resetSearchResult())
  }

  const _onClickProduct = async (productId: number, listTopicId?: number[]) => {
    modalHandler.hide()
    await submitSearchCountRequest(productId)
    setTimeout(() => {
      _navigate({
        pathname: ROUTER.PRODUCT_DETAIL,
        search: `id=${productId}&topic=${listTopicId?.toString()}`
      })
    }, 500)
  }

  const _onClickTopic = (topicId: number) => {
    modalHandler.hide()
    setTimeout(() => {
      _navigate({
        pathname: ROUTER.PRODUCTS,
        search: `topic=${topicId}&page=1&pageSize=${DEFAULT_PRODUCT_PAGES_SIZE}&sort=createdAt:desc`
      })
    }, 500)
  }

  const _onPressEnter = () => {
    if (searchKey.length >= 3) {
      modalHandler.hide()
      setTimeout(() => {
        _navigate({
          pathname: ROUTER.SEARCH,
          search: `key=${searchKey}`
        })
      }, 800)
    }
  }

  useEffect(() => {
    if (_bounceSearchKey.length >= 3) {
      _dispatch(getSearchThunk({ key: _bounceSearchKey }))
      return
    }

    !_bounceSearchKey && _dispatch(searchActions.resetSearchResult())
  }, [_bounceSearchKey])

  useLayoutEffect(() => {
    _dispatch(getSearchSuggestionThunk())
  }, [])

  return (
    <ModalWrapper
      destroyOnClose
      centered={false}
      afterClose={_resetSearchResult}
      modalHandler={props.modalHandler}
      className={styles.modalWrapper}
      footer={() => null}
      closable={false}
      afterOpenChange={(open) => open && _searchInputRef.current?.focus()}
    >
      <div className={styles.container}>
        <Input
          onPressEnter={_onPressEnter}
          ref={_searchInputRef}
          value={searchKey}
          onChange={({ target }) => setSearchKey(target.value)}
          autoCapitalize='none'
          autoComplete='off'
          autoCorrect='off'
          placeholder='Tìm kiếm'
          className={styles.searchInput}
          prefix={<MagnifyingGlass className={styles.searchIcon} />}
          suffix={
            <Fragment>
              {fetchingSearch ? (
                <Spin
                  indicator={<LoadingOutlined spin />}
                  size='default'
                  style={{ color: '#eb3c23' }}
                  className={`fade-in`}
                />
              ) : undefined}
              {searchKey.length && !fetchingSearch ? (
                <XSign
                  className={`fade-in-2s ${styles.xIcon}`}
                  onClick={() => setSearchKey('')}
                />
              ) : undefined}
            </Fragment>
          }
        />
        <div className={styles.searchResultContainer}>
          {/* SEARCH SUGGESTION */}
          {listSuggestedProduct.length &&
          !topics.length &&
          !products.length &&
          !_prevFetchingSearch &&
          !searchKey.length ? (
            <div className={styles.sectionContainer}>
              {listTrendingKeyword.length ? (
                <div className={styles.trendingKeywordContainer}>
                  {listTrendingKeyword.map((e) => {
                    return (
                      <p
                        key={e}
                        className={styles.trendingKeyword}
                        onClick={() => setSearchKey(e)}
                      >
                        #{e}
                      </p>
                    )
                  })}
                </div>
              ) : undefined}

              <p className={styles.title}>Thiết kế thịnh hành</p>
              {listSuggestedProduct.map((product, i) => {
                return (
                  <div
                    key={i}
                    className={`fade-in ${styles.sectionItem}`}
                    onClick={() => {
                      _onClickProduct(
                        product.id,
                        product.topics.map((t) => t.id)
                      )
                    }}
                  >
                    <img
                      className={styles.thumbnail}
                      loading='lazy'
                      src={product.coverImage?.formats.thumbnail.url}
                      alt='topic thumbnail'
                    />
                    <p>{product.name}</p>
                  </div>
                )
              })}
            </div>
          ) : undefined}
          {/* END SEARCH SUGGESTION */}

          {/** SEARCH RESULTS */}
          {topics.length ? (
            <div className={styles.sectionContainer}>
              <p className={styles.title}>Chủ đề</p>
              {topics.map((e, i) => {
                return (
                  <div
                    key={i}
                    className={`fade-in ${styles.sectionItem}`}
                    onClick={() => _onClickTopic(e.id)}
                  >
                    <img
                      loading='lazy'
                      className={styles.thumbnail}
                      src={e.coverImage?.formats.thumbnail.url}
                      alt='topic thumbnail'
                    />
                    <p>{e.title}</p>
                  </div>
                )
              })}
            </div>
          ) : undefined}

          {products.length ? (
            <div className={styles.sectionContainer}>
              <p className={styles.title}>Thiết kế</p>
              {products.map((e, i) => {
                return (
                  <div
                    key={i}
                    className={`fade-in ${styles.sectionItem}`}
                    onClick={() => {
                      _onClickProduct(
                        e.id,
                        e.topics.map((t) => t.id)
                      )
                    }}
                  >
                    <img
                      className={styles.thumbnail}
                      loading='lazy'
                      src={e.coverImage?.formats.thumbnail.url}
                      alt='topic thumbnail'
                    />
                    <p>{e.name}</p>
                  </div>
                )
              })}
            </div>
          ) : undefined}
          {/** SEARCH RESULTS */}

          {/** SEARCH NOT FOUND */}
          {_prevFetchingSearch && !topics.length && !products.length && (
            <div
              className='w-100 d-flex justify-content-center align-items-center'
              style={{ minHeight: 50 }}
            >
              <p className={styles.placeHolder}>
                Không tìm thấy kết quả phù hợp.
              </p>
            </div>
          )}
          {/** END SEARCH NOT FOUND */}
        </div>
      </div>
    </ModalWrapper>
  )
})

export default SearchModalView
