import { TError, TTopicState } from '@/types'
import { createSlice } from '@reduxjs/toolkit'
import { getListTopicFilterThunk, getListTopicThunk } from '../thunks'
import { RootState } from '..'

const initialState: TTopicState = {
  listTopic: [],
  listTopicFilter: []
}

export const TopicSlice = createSlice({
  name: 'TopicSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /** GET LIST TOPIC */
    builder.addCase(getListTopicThunk.pending, (state) => {
      state.fetchingListTopic = true
    })
    builder.addCase(getListTopicThunk.fulfilled, (state, action) => {
      state.listTopic = action.payload.data
      state.listTopicPagination = action.payload.pagination
      state.fetchingListTopic = false
      state.errorFetchListTopic = undefined
    })
    builder.addCase(getListTopicThunk.rejected, (state, action) => {
      state.fetchingListTopic = false
      state.errorFetchListTopic = action.payload as TError
    })

    /** GET LIST TOPIC FILTER */
    builder.addCase(getListTopicFilterThunk.pending, (state) => {
      state.fetchingListTopicFilter = true
    })
    builder.addCase(getListTopicFilterThunk.fulfilled, (state, action) => {
      state.listTopicFilter = action.payload.data
      state.listTopicFilterPagination = action.payload.pagination
      state.fetchingListTopicFilter = false
      state.errorFetchListTopicFilter = undefined
    })
    builder.addCase(getListTopicFilterThunk.rejected, (state, action) => {
      state.fetchingListTopicFilter = false
      state.errorFetchListTopicFilter = action.payload as TError
    })
  }
})

export const selectTopicState = (state: RootState) => state.topicState

export default TopicSlice.reducer
