import { TError } from '@/types'
import { TRequiredFormState } from '@/types'
import { createSlice } from '@reduxjs/toolkit'
import { submitRequiredFormThunk } from '../thunks'
import { RootState } from '..'

const initialState: TRequiredFormState = {}

export const RequiredFormSlice = createSlice({
  name: 'RequiredFormSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(submitRequiredFormThunk.pending, (state) => {
      state.fetchingSubmitRequiredForm = true
    })
    builder.addCase(submitRequiredFormThunk.fulfilled, (state) => {
      state.fetchingSubmitRequiredForm = false
      state.errorSubmitRequiredForm = undefined
    })
    builder.addCase(submitRequiredFormThunk.rejected, (state, action) => {
      state.fetchingSubmitRequiredForm = false
      state.errorSubmitRequiredForm = action.payload as TError
    })
  }
})

export const selectRequiredFormState = (state: RootState) =>
  state.requiredFormState

export default RequiredFormSlice.reducer
