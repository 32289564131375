import { TError, THomeConfigState } from '@/types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'
import { getHomeConfigThunk } from '../thunks'

const initialState: THomeConfigState = {
  suggestionProducts: [],
  highlightTopics: [],
  favoredProducts: [],
  slideShow: []
}

export const HomeConfigSlice = createSlice({
  name: 'HomeConfigSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /** GET HOME CONFIG */
    builder.addCase(getHomeConfigThunk.pending, (state) => {
      state.fetchingHomeConfig = true
    })
    builder.addCase(getHomeConfigThunk.fulfilled, (state, action) => {
      state.suggestionProducts = action.payload.suggestionProducts
      state.highlightTopics = action.payload.highlightTopics
      state.favoredProducts = action.payload.favoredProducts
      state.slideShow = action.payload.slideShow

      state.fetchingHomeConfig = false
      state.errorFetchHomeConfig = undefined
    })
    builder.addCase(getHomeConfigThunk.rejected, (state, action) => {
      state.fetchingHomeConfig = false
      state.errorFetchHomeConfig = action.payload as TError
    })
  }
})

export const selectHomeConfigState = (state: RootState) => state.homeConfigState
export default HomeConfigSlice.reducer
