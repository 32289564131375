import { useEffect } from 'react'

const PrivacyPolicyPageView = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='page-content'>
      {/* contact area */}
      <section className='content-inner-1 shop-account'>
        <div className='container'>
          <div className='row'>
            {/* Left part start */}
            <div className='col-lg-8 col-md-7 col-sm-12 inner-text w-100'>
              <h4 className='title'>Chính sách bảo mật</h4>
              <p className='m-b30'>
                Chính sách bảo mật cho bạn biết cách chúng tôi thu thập, sử dụng
                thông tin cá nhân của bạn tại trang web này. Vui lòng đọc chính
                sách bảo mật trước khi sử dụng trang web hoặc gửi bất kỳ thông
                tin cá nhân nào. Khi sử dụng trang web, chính sách của chúng tôi
                là giữ kín thông tin cá nhân nhận được từ trang web hoàn toàn bí
                mật và chỉ dùng cho mục đích nội bộ. Chúng tôi sẽ không chia sẻ
                thông tin cá nhân của bạn với bất kỳ bên nào khác. Hãy yên tâm
                rằng chúng tôi tôn trọng sự riêng tư của bạn và xử lý dữ liệu cá
                nhân của bạn với sự cẩn trọng tối đa.
              </p>

              <div className='dlab-divider bg-gray-dark'></div>
              <h4 className='title'>Thu thập thông tin của bạn</h4>

              <p className='m-b10'>
                Chúng tôi thu thập dữ liệu cá nhân của bạn khi bạn truy cập
                trang web của chúng tôi, đăng ký sử dụng sản phẩm hoặc dịch vụ,
                đóng góp hoặc tương tác với chúng tôi. Chúng tôi sẽ chỉ thu thập
                dữ liệu cá nhân của bạn theo luật hiện hành. Chúng tôi thu thập
                dữ liệu cá nhân của bạn theo nhiều cách khác nhau:
              </p>
              <ul className='list-check primary m-a0'>
                <li>
                  Trực tiếp từ bạn, khi bạn đăng ký dịch vụ của chúng tôi và khi
                  bạn duyệt trang web của chúng tôi.
                </li>
                <li>
                  Dữ liệu cá nhân chúng tôi tạo ra về bạn, ví dụ: dữ liệu cá
                  nhân mà chúng tôi sử dụng để xác thực bạn hoặc dữ liệu cá nhân
                  ở dạng địa chỉ IP của bạn hoặc tùy chọn của bạn.
                </li>
                <li>
                  Dữ liệu cá nhân mà chúng tôi thu thập từ các bên thứ ba, ví
                  dụ: dữ liệu cá nhân giúp chúng tôi chống gian lận hoặc chúng
                  tôi thu thập, với sự cho phép của bạn, khi bạn tương tác với
                  các tài khoản mạng xã hội của mình.
                </li>
              </ul>
              <p />

              <h4 className='title'>
                Khi bạn đăng ký tài khoản trên Sencam.vn, chúng tôi thu thập:
              </h4>
              <ul className='list-check primary m-a0'>
                <li>Tên của bạn.</li>
                <li>Địa chỉ email của bạn.</li>
                <li>
                  Các chi tiết khác như địa chỉ cư trú hoặc địa chỉ thanh toán
                  của bạn khi bạn đăng ký thành viên hoặc đăng ký.
                </li>
                <li>
                  Một số dữ liệu hạn chế từ hồ sơ mạng xã hội của bạn, nếu bạn
                  đã đăng nhập vào website Sencam.vn bằng cách sử dụng chi tiết
                  mạng xã hội của mình.
                </li>
                <li>
                  Ảnh của bạn, nếu bạn thêm một bức ảnh vào trang hồ sơ của
                  mình.
                </li>
                <p />
                <p>
                  Bạn có thể thay đổi hoặc xóa các chi tiết này bằng cách sử
                  dụng chức năng cài đặt Thông tin cá nhân trong tài khoản của
                  mình.
                </p>
                <li>Dữ liệu cá nhân chúng tôi tạo ra về bạn.</li>
                <p />
                <p style={{ paddingLeft: 32 }}>
                  Khi bạn đăng ký tài khoản, chúng tôi chỉ định cho bạn một số
                  ID duy nhất mà chúng tôi sử dụng để nhận dạng bạn khi bạn đăng
                  nhập vào các dịch vụ của chúng tôi.
                </p>
                <li>Sử dụng mạng xã hội của bạn để đăng nhập vào tài khoản.</li>
                <p />
                <p style={{ paddingLeft: 32 }}>
                  Khi bạn đăng nhập vào trang web của chúng tôi bằng thông tin
                  đăng nhập Facebook của bạn, bạn cho phép Facebook chia sẻ với
                  chúng tôi địa chỉ email của bạn và một số khía cạnh nhất định
                  của hồ sơ Facebook nếu bạn đã công khai những điều này trên hồ
                  sơ Facebook của mình. Điều này chỉ bao gồm họ và tên, độ tuổi,
                  liên kết đến hồ sơ Facebook và ảnh hồ sơ của bạn. Chúng tôi
                  không có quyền truy cập vào các cập nhật trên hồ sơ Facebook
                  của bạn. Nếu bạn sử dụng chi tiết đăng nhập Google của mình,
                  bạn cho phép Google chia sẻ dữ liệu cá nhân mà bạn đã công
                  khai trong hồ sơ trên Google của mình. Thông tin này chỉ bao
                  gồm họ và tên, địa chỉ email của bạn và liệu địa chỉ email của
                  bạn đã được xác thực hay chưa, độ tuổi của bạn, liên kết đến
                  hồ sơ trên Google của bạn và nếu có, ảnh hồ sơ của bạn. Nếu
                  bạn sử dụng chi tiết đăng nhập Zalo của mình, bạn cho phép
                  Zalo chia sẻ dữ liệu cá nhân mà bạn đã công khai trong hồ sơ
                  trên Zalo của mình. Thông tin này chỉ bao gồm họ và tên, địa
                  chỉ email của bạn và liệu địa chỉ email của bạn đã được xác
                  thực hay chưa, độ tuổi của bạn, liên kết đến hồ sơ trên Zalo
                  của bạn và nếu có, ảnh hồ sơ của bạn. Sau đó, chúng tôi sẽ sử
                  dụng dữ liệu cá nhân này để tạo hồ sơ cho tài khoản.
                </p>
              </ul>
              <p />
              <h4 className='title'>Phân phối thông tin</h4>
              <p>
                Chúng tôi xử lý tất cả dữ liệu khách hàng phù hợp với Pháp lệnh
                dữ liệu cá nhân (Riêng tư) ở Việt Nam. Chúng tôi sẽ không bao
                giờ bán, chia sẻ hoặc sử dụng bất kỳ thông tin cá nhân nào bạn
                cung cấp cho chúng tôi vì bất kỳ mục đích nào khác hơn là trao
                đổi với bạn về các sản phẩm, việc đặt chỗ hoặc thư mời đến các
                sự kiện của chúng tôi. Chúng tôi có thể chia sẻ thông tin với
                các cơ quan chính phủ hoặc các công ty khác hỗ trợ chúng tôi
                trong việc phòng chống gian lận hoặc điều tra.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Privacy Policy END */}
    </div>
  )
}

export default PrivacyPolicyPageView
