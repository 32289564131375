import { getListTopicFilterRequest, getListTopicRequest } from '@/services'
import { TGetListTopicParams } from '@/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getListTopicThunk = createAsyncThunk(
  'TopicAsyncThunk/getListTopicThunk',
  async (
    payload: TGetListTopicParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { data, pagination, error } = await getListTopicRequest(payload)

    if (data) return fulfillWithValue({ data, pagination })
    return rejectWithValue(error)
  }
)

export const getListTopicFilterThunk = createAsyncThunk(
  'TopicAsyncThunk/getListTopicFilterThunk',
  async (
    payload: TGetListTopicParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { data, pagination, error } = await getListTopicFilterRequest(payload)
    if (data) return fulfillWithValue({ data, pagination })
    return rejectWithValue(error)
  }
)
