import { ROUTER, STORAGE_KEY } from '@/constants'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { selectAuthState } from '@/redux/slices'
import { getMeThunk } from '@/redux/thunks'
import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const PublicRoute = () => {
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(selectAuthState)

  const isLoggedIn = localStorage.getItem(STORAGE_KEY.IS_LOGGED_IN)

  useEffect(() => {
    if (isLoggedIn && !currentUser) dispatch(getMeThunk())
  }, [isLoggedIn, currentUser])

  return isLoggedIn ? <Navigate replace to={ROUTER.HOME} /> : <Outlet />
}
