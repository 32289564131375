import { TAuth, TError, TResponse, TSignInParams, TSignUpParams } from '@/types'
import { axiosClient } from './Axios'

export const signInRequest = async (
  payload: TSignInParams
): Promise<TResponse<{ jwt: string; user: TAuth }>> => {
  try {
    const { data } = await axiosClient.post<
      { jwt: string; user: TAuth },
      TSignInParams
    >({
      path: 'auth/local',
      data: payload
    })

    return { data: data }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}

export const signUpRequest = async (
  payload: TSignUpParams
): Promise<TResponse<{ jwt: string; user: TAuth }>> => {
  try {
    const { data } = await axiosClient.post<
      { jwt: string; user: TAuth },
      TSignUpParams
    >({
      path: 'auth/local/register',
      data: payload
    })

    return { data: data }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}

export const getMeRequest = async (): Promise<TResponse<TAuth>> => {
  try {
    const { data } = await axiosClient.get<TAuth, any>({ path: 'users/me' })
    return { data: data }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error as TError }
  }
}

export const deleteAccountRequest = async (
  userId: number
): Promise<TResponse<boolean>> => {
  try {
    const { data } = await axiosClient.delete<any, number>({
      path: `users/${userId}`
    })

    return { data }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}
