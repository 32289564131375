import { combineReducers, configureStore } from '@reduxjs/toolkit'
import AuthSlice from './slices/AuthSlice'
import HomeConfigSlice from './slices/HomeConfigSlice'
import ProductSlice from './slices/ProductSlice'
import RequiredFormSlice from './slices/RequiredFormSlice'
import SearchSlice from './slices/SearchSlice'
import TopicSlice from './slices/TopicSlice'
import WebConfigSlice from './slices/WebConfigSlice'

const appReducer = combineReducers({
  authState: AuthSlice,
  productState: ProductSlice,
  topicState: TopicSlice,
  webConfigState: WebConfigSlice,
  homeConfigState: HomeConfigSlice,
  searchState: SearchSlice,
  requiredFormState: RequiredFormSlice
})

const rootReducer = (state: any, action: any) => {
  /** THIS ACTION TO CLEAR ALL STATE WHEN LOG OUT */
  // if (action.type === 'AuthSlice/logOut') return appReducer(undefined, action)
  return appReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
