import { ROUTER } from '@/constants'
import { useAppDispatch, useAppSelector, usePrevious } from '@/hooks'
import { selectAuthState } from '@/redux/slices'
import { signUpThunk } from '@/redux/thunks'
import { Form, FormProps, Input, notification } from 'antd'
import { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '@/assets/images/sencam.png'
import { RuleObject } from 'antd/es/form'

type FieldType = {
  username: string
  password: string
  email: string
}
export const COMMON_NAME_REGEX = /^[a-zA-Z0-9\-_]{4,32}$/

const accountNameValidator = (_: RuleObject, value: string) => {
  if (!value) {
    return Promise.resolve()
  }
  if (!COMMON_NAME_REGEX.test(value)) {
    return Promise.reject('Tên đăng nhập không hợp lệ')
  }
  return Promise.resolve()
}

const SignUpView = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { fetchingSignUp, errorSignUp } = useAppSelector(selectAuthState)
  const [api, contextHolder] = notification.useNotification()

  const prevFetchingSignUp = usePrevious(fetchingSignUp)

  const _onFinish: FormProps<FieldType>['onFinish'] = (data) => {
    if (data) {
      dispatch(signUpThunk(data))
      api.info({ message: 'Đang xử lý' })
    }
  }

  useEffect(() => {
    if (prevFetchingSignUp && !fetchingSignUp) {
      if (!errorSignUp) return navigate(ROUTER.HOME, { replace: true })
      // api.error({ message: errorSignUp.message })
      api.error({ message: 'Đăng ký thất bại' })
    }
  }, [prevFetchingSignUp, errorSignUp])

  return (
    <Fragment>
      {contextHolder}
      <div className='page-content'>
        {/* contact area */}
        <section className='content-inner shop-account'>
          {/* Product */}
          <div className='container d-flex flex-column align-items-center'>
            <img
              src={logo}
              alt='logo'
              style={{ height: 120, marginBottom: 25 }}
            />
            <div className='row justify-content-center'>
              <div className='col-lg-6 col-md-6 mb-4'>
                <div className='login-area'>
                  <Form onFinish={_onFinish} autoComplete='off'>
                    <h4 className='text-secondary mb-4'>Đăng ký tài khoản</h4>

                    <div className='mb-4'>
                      <label className='label-title'>Tên đăng nhập *</label>
                      <Form.Item<FieldType>
                        name='username'
                        rules={[
                          {
                            required: true,
                            message: 'Tên đăng nhập không được bỏ trống'
                          },
                          { validator: accountNameValidator }
                        ]}
                      >
                        <Input
                          name='dzName'
                          required={true}
                          className='form-control'
                          placeholder='Tên đăng nhập (Viết liền không dấu, không có kí tự đặc biệt)'
                        />
                      </Form.Item>
                    </div>
                    <div className='mb-4'>
                      <label className='label-title'>Địa chỉ email *</label>
                      <Form.Item<FieldType> name='email'>
                        <Input
                          name='dzName'
                          required={true}
                          className='form-control'
                          placeholder='Nhập địa chỉ email'
                          type='email'
                        />
                      </Form.Item>
                    </div>
                    <div className='mb-4'>
                      <label className='label-title'>Mật khẩu *</label>
                      <Form.Item<FieldType> name='password'>
                        <Input
                          name='dzName'
                          required={true}
                          autoComplete='off'
                          className='form-control'
                          placeholder='Nhập mật khẩu'
                          type='password'
                        />
                      </Form.Item>
                    </div>
                    <div className='mb-5'>
                      <small>
                        Dữ liệu cá nhân của bạn sẽ được sử dụng để hỗ trợ trải
                        nghiệm của bạn trên toàn bộ trang web này, để quản lý
                        quyền truy cập vào tài khoản của bạn và cho các mục đích
                        khác được mô tả trong{' '}
                        <a href={ROUTER.PRIVACY_POLICY}> chính sách bảo mật </a>{' '}
                        của chúng tôi.
                      </small>
                    </div>
                    <div className='text-left'>
                      <Form.Item>
                        <button
                          type='submit'
                          className='btn btn-primary btnhover w-100 me-2'
                        >
                          Đăng ký
                        </button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {/* Product END */}
        </section>
        {/* contact area End*/}
      </div>
    </Fragment>
  )
}

export default SignUpView
