import { useEffect } from 'react'

const ComplaintPolicyPageView = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='page-content'>
      {/* contact area */}
      <section className='content-inner-1 shop-account'>
        <div className='container'>
          <div className='row'>
            {/* Left part start */}
            <div className='col-lg-8 col-md-7 col-sm-12 inner-text w-100'>
              <h4 className='title'>
                Mục đích giải quyết thắc mắc, khiếu nại:
              </h4>
              <p className='m-b10'>
                Chúng tôi mong muốn đem đến cho khách hàng những trải nghiệm tốt
                nhất, sản phẩm chất lượng và chăm sóc khách hàng tận tình, chu
                đáo.
              </p>
              <ul className='list-check primary m-a0'>
                <li>
                  Chúng tôi sẽ thực hiện các bước xác minh yêu cầu khiếu nại đầy
                  đủ, lắng nghe ý kiến khách hàng và đưa ra biện pháp giải
                  quyết.
                </li>
                <li>
                  Trong quá trình sử dụng dịch vụ, nếu có ý kiến thắc mắc nào về
                  sản phẩm, dịch vụ, hay phát hiện thiếu sót trong nghiệp vụ,
                  tác phong và tinh thần phục vụ, rất mong nhận được phản hồi từ
                  quý khách hàng.
                </li>
              </ul>
              <p />
              <div className='dlab-divider bg-gray-dark'></div>
              <h4 className='title'>
                Quy trình giải quyết thắc mắc, khiếu nại:
              </h4>
              <ul className='list-check primary m-a0'>
                <li>
                  Bước 1: Khách hàng vui lòng liên hệ với chúng tôi qua số điện
                  thoại 058.514.5549 (tất cả các ngày trong tuần, kể cả ngày lễ)
                  hoặc gửi thư về địa chỉ email info.sencam@gmail.com.
                </li>
                <li>
                  Bước 2: Nhân viên chăm sóc khách hàng sẽ ghi nhận và tiến hành
                  xác minh thông tin, hỗ trợ phản hồi khách hàng trong thời gian
                  sớm nhất.
                </li>
                <li>
                  Bước 3: Tiến hành giải quyết khiếu nại, thắc mắc của khách
                  hàng trong giờ hành chính các ngày trong tuần.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Privacy Policy END */}
    </div>
  )
}

export default ComplaintPolicyPageView
