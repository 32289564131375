import { ReactComponent as MagnifyingGlass } from '@/assets/icons/magnifyingGlass.svg'
import profile0 from '@/assets/images/profile0.png'
import logo from '@/assets/images/sencam.png'
import { GoogleSignInButton } from '@/components'
import {
  DEFAULT_PAGES_SIZE,
  DEFAULT_PRODUCT_PAGES_SIZE,
  ROUTER
} from '@/constants'
import { AuthModalContext } from '@/contexts'
import { useAppDispatch, useAppSelector, useModalHandler } from '@/hooks'
import { SearchModalView } from '@/pages'
import {
  authActions,
  searchActions,
  selectAuthState,
  selectWebConfigState
} from '@/redux/slices'
import { getWebConfigThunk } from '@/redux/thunks'
import styles from '@/styles/layout/AppLayout.module.scss'
import { Drawer, Input } from 'antd'
import { useContext, useLayoutEffect, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

export const AppLayout = () => {
  const _navigate = useNavigate()
  const _dispatch = useAppDispatch()

  const { currentUser } = useAppSelector(selectAuthState)
  const { webConfig } = useAppSelector(selectWebConfigState)

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  const { isLoggedIn, openAuthModal } = useContext(AuthModalContext)

  const _searchModalHandler = useModalHandler()

  const _onLogIN = () => {
    if (!isLoggedIn) return openAuthModal()
  }

  const _onLogOut = () => {
    _dispatch(authActions.logOut())
    _navigate(ROUTER.HOME, { replace: true })
  }

  const _toggleDrawer = () => setIsOpenDrawer((prev) => !prev)

  const _onClickDrawerItem = (route: ROUTER, params?: string) => {
    _navigate({ pathname: route, search: params })
    _toggleDrawer()
  }

  useLayoutEffect(() => {
    _dispatch(getWebConfigThunk())
  }, [])

  return (
    <div className='page-wraper'>
      <header className={styles.header}>
        <div className={styles.leftHeader}>
          <div className='logo-header logo-dark'>
            <Link to={ROUTER.HOME}>
              <img src={logo} alt='logo' className='fade-in-2s' />
            </Link>
          </div>
          <nav className={styles.navBarContainer}>
            <Link to={ROUTER.HOME}>
              <span className={styles.routeName}>Trang chủ</span>
            </Link>
            <Link
              to={{
                pathname: ROUTER.TOPIC,
                search: `page=1&pageSize=${DEFAULT_PAGES_SIZE}&sort=createdAt:desc`
              }}
            >
              <span className={styles.routeName}>Chủ đề</span>
            </Link>
            <Link
              to={{
                pathname: ROUTER.PRODUCTS,
                search: `page=1&pageSize=${DEFAULT_PRODUCT_PAGES_SIZE}&sort=createdAt:desc`
              }}
            >
              <span className={styles.routeName}>Sản Phẩm</span>
            </Link>
          </nav>
        </div>

        <div className={styles.rightHeader}>
          <div
            onClick={() => {
              _dispatch(searchActions.resetSearchResult())
              _searchModalHandler.show()
            }}
            className={styles.searchInputWrapper}
          >
            <Input
              readOnly
              className={styles.searchInput}
              placeholder='Tìm kiếm'
              suffix={<MagnifyingGlass className={styles.magnifyingGlass} />}
            />
          </div>
          <MagnifyingGlass
            className={styles.searchIcon}
            onClick={_searchModalHandler.show}
          />
          <SearchModalView modalHandler={_searchModalHandler} />

          {/** EXTRA NAV */}
          <div className={`extra-nav ${styles.extraNav}`}>
            <div className='extra-cell'>
              <ul className='navbar-nav header-right ms-0'>
                {currentUser ? (
                  <li className='nav-item dropdown profile-dropdown ms-4'>
                    <a
                      className='nav-link'
                      href='javascript:void(0);'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {/* <img src={profile1} alt='profile1' /> */}
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 50,
                          backgroundColor: '#7779',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img alt='' src={profile0} />
                      </div>
                      <div className='profile-info'>
                        <h6 className='title'>{currentUser?.username}</h6>
                        <span>{currentUser?.email}</span>
                      </div>
                    </a>
                    <div className='dropdown-menu py-0 dropdown-menu-end'>
                      {/* <div className='dropdown-header'>
                        <h6 className='m-0'>{currentUser?.username}</h6>
                        <span>{currentUser?.email}</span>
                      </div> */}
                      <div className='dropdown-body'>
                        <Link
                          to={ROUTER.USER_PROFILE}
                          className='dropdown-item d-flex justify-content-between align-items-center ai-icon'
                        >
                          <div>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              height='20px'
                              viewBox='0 0 24 24'
                              width='20px'
                              fill='#000000'
                            >
                              <path d='M0 0h24v24H0V0z' fill='none' />
                              <path d='M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z' />
                            </svg>
                            <span className='ms-2'>Cá nhân</span>
                          </div>
                        </Link>
                        {/* <a
                          href='shop-cart.html'
                          className='dropdown-item d-flex justify-content-between align-items-center ai-icon'
                        >
                          <div>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              height='20px'
                              viewBox='0 0 24 24'
                              width='20px'
                              fill='#000000'
                            >
                              <path d='M0 0h24v24H0V0z' fill='none' />
                              <path d='M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z' />
                            </svg>
                            <span className='ms-2'>Giỏ hàng</span>
                          </div>
                        </a>
                        <a
                          href='wishlist.html'
                          className='dropdown-item d-flex justify-content-between align-items-center ai-icon'
                        >
                          <div>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              height='20px'
                              viewBox='0 0 24 24'
                              width='20px'
                              fill='#000000'
                            >
                              <path d='M0 0h24v24H0V0z' fill='none' />
                              <path d='M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z' />
                            </svg>
                            <span className='ms-2'>Theo dõi</span>
                          </div>
                        </a> */}
                      </div>
                      <div className='dropdown-footer'>
                        <button
                          onClick={_onLogOut}
                          type='button'
                          className='btn btn-primary w-100 btnhover btn-sm'
                        >
                          Đăng Xuất
                        </button>
                      </div>
                    </div>
                  </li>
                ) : (
                  <button
                    onClick={_onLogIN}
                    type='button'
                    className='btn btn-primary w-100 btnhover btn-sm'
                  >
                    Đăng Nhập
                  </button>
                )}
              </ul>
            </div>
          </div>
          {/** END EXTRA NAV */}

          <Link
            to={ROUTER.REQUEST_SERVICE}
            className={`btn btn-primary btn-sm ms-4   ${styles.getInTouchButton}`}
          >
            Đăng ký tư vấn
          </Link>

          {/** TOGGLE DRAWER BUTTON */}
          <button
            className={`navbar-toggler collapsed navicon justify-content-end ${styles.navToggleButton}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNavDropdown'
            aria-controls='navbarNavDropdown'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={_toggleDrawer}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          {/** END TOGGLE DRAWER BUTTON */}
        </div>

        {/** DRAWER */}
        <Drawer
          title={
            <img
              src={logo}
              alt='logo'
              className='w-50'
              loading='lazy'
              style={{ cursor: 'pointer' }}
              onClick={() => _onClickDrawerItem(ROUTER.HOME)}
            />
          }
          closable
          placement={'left'}
          onClose={_toggleDrawer}
          open={isOpenDrawer}
          destroyOnClose
        >
          <nav className={styles.navBarContainer}>
            <h1 onClick={() => _onClickDrawerItem(ROUTER.HOME)}>
              <span className={styles.routeName}>Trang chủ</span>
            </h1>
            <h1
              onClick={() => {
                _onClickDrawerItem(
                  ROUTER.TOPIC,
                  `page=1&pageSize=${DEFAULT_PAGES_SIZE}&sort=createdAt:desc`
                )
              }}
            >
              <span className={styles.routeName}>Chủ đề</span>
            </h1>
            <h1
              onClick={() => {
                _onClickDrawerItem(
                  ROUTER.PRODUCTS,
                  `page=1&pageSize=${DEFAULT_PRODUCT_PAGES_SIZE}&sort=createdAt:desc`
                )
              }}
            >
              <span className={styles.routeName}>Sản Phẩm</span>
            </h1>
            <h1 onClick={() => _onClickDrawerItem(ROUTER.REQUEST_SERVICE)}>
              <span className={styles.routeName}>Đăng ký tư vấn</span>
            </h1>
            {currentUser && (
              <h1 onClick={() => _onClickDrawerItem(ROUTER.USER_PROFILE)}>
                <span className={styles.routeName}>Trang cá nhân</span>
              </h1>
            )}
          </nav>
          <div className='dz-social-icon'>
            <a
              rel='noreferrer'
              className='fab fa-facebook-f'
              href={webConfig?.facebook}
              target='_blank'
            />
            <a
              rel='noreferrer'
              className='fa-brands fa-youtube'
              href={webConfig?.youtube}
              target='_blank'
            />
          </div>

          {!currentUser ? (
            <div className='d-flex flex-column justify-content-center align-items-center gap-4'>
              <button
                onClick={_onLogIN}
                type='button'
                className='btn btn-primary w-100 btnhover btn-sm'
              >
                Đăng Nhập
              </button>
              <div className='d-flex gap-3 align-self-center'>
                <GoogleSignInButton />
              </div>
            </div>
          ) : undefined}
        </Drawer>
        {/** END DRAWER */}
      </header>

      <Outlet />

      {/* Footer */}
      <footer className='site-footer style-1'>
        {/* Footer Top */}
        <div className='footer-top'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-xl-4 col-lg-3 col-md-12 col-sm-12 wow fadeInUp'
                data-wow-delay='0.1s'
              >
                <div className='widget widget_about'>
                  <div className='footer-logo logo-white'>
                    <a href='index.html'>
                      <img src='images/logo.png' alt='' />
                    </a>
                  </div>
                  <div className='dz-social-icon style-1'>
                    <ul>
                      <li>
                        <a
                          rel='noreferrer'
                          href={webConfig?.facebook}
                          target='_blank'
                        >
                          <i className='fa-brands fa-facebook-f'></i>
                        </a>
                      </li>
                      <li>
                        <a
                          rel='noreferrer'
                          href={webConfig?.youtube}
                          target='_blank'
                        >
                          <i className='fa-brands fa-youtube'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className='col-xl-4 col-lg-3 col-md-12 col-sm-12 wow fadeInUp'
                data-wow-delay='0.2s'
              >
                <div className='widget widget_services'>
                  <h5 className='footer-title'>Chính sách</h5>
                  <ul>
                    <li>
                      <Link to={ROUTER.PRIVACY_POLICY}>Chính Sách Bảo Mật</Link>
                    </li>
                    <li>
                      <Link to={ROUTER.CUSTOMER_COMPLAINT_POLICY}>
                        Chính Sách Khiếu Nại
                      </Link>
                    </li>
                    <li>
                      <Link to={ROUTER.SALES_POLICIES}>
                        Chính Sách Bán Hàng
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className='col-xl-4 col-lg-3 col-md-12 col-sm-12 wow fadeInUp'
                data-wow-delay='0.5s'
              >
                <div className='widget widget_getintuch'>
                  <h5 className='footer-title'>Liên Hệ</h5>
                  <ul>
                    {webConfig?.address && (
                      <li>
                        <i className='flaticon-placeholder'></i>
                        <span>{webConfig?.address}</span>
                      </li>
                    )}
                    <li>
                      <i className='flaticon-phone'></i>
                      <span>{webConfig?.phone}</span>
                    </li>
                    <li>
                      <i className='flaticon-email'></i>
                      <span>{webConfig?.contactEmail}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Top End */}

        {/* Footer Bottom */}
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row fb-inner'>
              <div className='col-lg-6 col-md-12 text-start'>
                <p className='copyright-text'>
                  Sencam Website - © 2024 All Rights Reserved
                </p>
              </div>
              {/* <div className='col-lg-6 col-md-12 text-end'>
                <p>
                  Made with <span className='heart'></span> by
                  <a href='https://dexignzone.com/'>DexignZone</a>
                </p>
              </div> */}
            </div>
          </div>
        </div>
        {/* Footer Bottom End */}
      </footer>
      {/* Footer End */}

      <button className='scroltop' type='button'>
        <i className='fas fa-arrow-up'></i>
      </button>
    </div>
  )
}
