import styles from '@/styles/layout/AuthLayout.module.scss'
import { Outlet } from 'react-router-dom'

export const AuthLayout = () => {
  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  )
}
