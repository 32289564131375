import { RootState } from '@/redux'
import { TAuthState, TError } from '@/types'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteAccountThunk,
  getMeThunk,
  signInThunk,
  signUpThunk
} from '../thunks'

const initialState: TAuthState = {
  fetchingSignIn: false,

  fetchingSignUp: false,

  fetchingGetMe: false
}

export const AuthSlice = createSlice({
  name: 'AuthSlice',
  initialState,
  reducers: {
    logOut: (state) => {
      state.currentUser = undefined
      localStorage.clear()
    }
  },
  extraReducers: (builder) => {
    /** GET ME */
    builder.addCase(getMeThunk.pending, (state) => {
      state.currentUser = undefined
    })
    builder.addCase(getMeThunk.fulfilled, (state, action) => {
      state.currentUser = action.payload
    })
    builder.addCase(getMeThunk.rejected, (state, action) => {
      state.errorGetMe = action.payload as TError
    })

    /** SIGN IN */
    builder.addCase(signInThunk.pending, (state) => {
      state.fetchingSignIn = true
      state.currentUser = undefined
    })
    builder.addCase(signInThunk.fulfilled, (state, action) => {
      state.currentUser = action.payload
      state.fetchingSignIn = false
      state.errorSignIn = undefined
    })
    builder.addCase(signInThunk.rejected, (state, action) => {
      state.fetchingSignIn = false
      state.errorSignIn = action.payload as TError
    })

    /** SIGN UP */
    builder.addCase(signUpThunk.pending, (state) => {
      state.fetchingSignUp = true
    })
    builder.addCase(signUpThunk.fulfilled, (state, action) => {
      state.currentUser = action.payload
      state.fetchingSignUp = false
      state.errorSignUp = undefined
    })
    builder.addCase(signUpThunk.rejected, (state, action) => {
      state.fetchingSignUp = false
      state.errorSignUp = action.payload as TError
    })

    /** DELETE ACCOUNT */
    builder.addCase(deleteAccountThunk.pending, (state) => {
      state.fetchingDeleteAccount = true
    })
    builder.addCase(deleteAccountThunk.fulfilled, (state) => {
      state.fetchingDeleteAccount = false
      state.errorDeleteAccount = undefined
    })
    builder.addCase(deleteAccountThunk.rejected, (state, action) => {
      state.fetchingDeleteAccount = false
      state.errorDeleteAccount = action.payload as TError
    })
  }
})

export const authActions = AuthSlice.actions
export const selectAuthState = (state: RootState) => state.authState

export default AuthSlice.reducer
