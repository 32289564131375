import {
  TError,
  THomeConfig,
  TNetworkResponse,
  TProduct,
  TResponse,
  TTopic
} from '@/types'
import { axiosClient } from './Axios'
import { HomeConfigDTO } from '@/types/dto'
import { mapSnakeCaseToCamelCase } from '@/utils'
import { TImage } from '@/types/ImageType'

export const getHomeConfigRequest = async (): Promise<
  TResponse<THomeConfig>
> => {
  try {
    const params: Record<string, any> = {
      'populate[suggestion_products][populate]': 'cover_image,topics',
      'populate[highlight_topics][populate]': 'cover_image',
      'populate[favored_products][populate]': 'cover_image,topics',
      'populate[new_slide_show][populate]': 'image'
    }

    const { data } = await axiosClient.get<
      TNetworkResponse<HomeConfigDTO>,
      any
    >({
      path: 'home-config',
      params
    })

    return {
      //@ts-ignore
      data: {
        ...data.data,
        suggestionProducts:
          data?.data?.suggestion_products?.map((e) =>
            mapSnakeCaseToCamelCase<TProduct>(e)
          ) ?? [],
        favoredProducts:
          data?.data?.favored_products?.map((e) =>
            mapSnakeCaseToCamelCase<TProduct>(e)
          ) ?? [],
        highlightTopics:
          data?.data?.highlight_topics?.map((e) =>
            mapSnakeCaseToCamelCase<Omit<TTopic, 'products'>>(e)
          ) ?? [],
        slideShow:
          data?.data?.new_slide_show?.map((e) =>
            mapSnakeCaseToCamelCase<{
              link: string
              image: TImage
            }>(e)
          ) ?? []
      }
    }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}
