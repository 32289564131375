import { useModalHandler } from '@/hooks/useModalHandler'
import { Modal, ModalProps } from 'antd'
import { memo } from 'react'

type ModalWrapperProps = {
  modalHandler: ReturnType<typeof useModalHandler>
} & ModalProps

export const ModalWrapper = memo((props: ModalWrapperProps) => {
  const { modalHandler } = props

  return (
    <Modal
      centered
      destroyOnClose
      open={modalHandler.open}
      onCancel={modalHandler.hide}
      {...props}
    >
      {props.children}
    </Modal>
  )
})
