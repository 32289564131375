import { STORAGE_KEY } from '@/constants'
import {
  deleteAccountRequest,
  getMeRequest,
  signInRequest,
  signUpRequest
} from '@/services'
import { TSignInParams, TSignUpParams } from '@/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const signInThunk = createAsyncThunk(
  'AuthAsyncThunk/signInThunk',
  async (payload: TSignInParams, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await signInRequest(payload)
    if (data?.user) {
      localStorage.setItem(STORAGE_KEY.IS_LOGGED_IN, 'true')
      localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, data.jwt)
      return fulfillWithValue(data.user)
    }
    return rejectWithValue(error)
  }
)

export const signUpThunk = createAsyncThunk(
  'AuthAsyncThunk/signUpThunk',
  async (payload: TSignUpParams, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await signUpRequest(payload)
    if (data?.user) {
      localStorage.setItem(STORAGE_KEY.IS_LOGGED_IN, 'true')
      localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, data.jwt)
      return fulfillWithValue(data.user)
    }
    return rejectWithValue(error)
  }
)

export const getMeThunk = createAsyncThunk(
  'AuthAsyncThunk/getMeThunk',
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await getMeRequest()
    if (data) {
      localStorage.setItem(STORAGE_KEY.IS_LOGGED_IN, 'true')
      return fulfillWithValue(data)
    }
    localStorage.removeItem(STORAGE_KEY.IS_LOGGED_IN)
    localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN)
    return rejectWithValue(error)
  }
)

export const deleteAccountThunk = createAsyncThunk(
  'AuthAsyncThunk/deleteAccountThunk',
  async (payload: number, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await deleteAccountRequest(payload)
    if (data) return fulfillWithValue(data)
    return rejectWithValue(error)
  }
)
