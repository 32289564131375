import { DEFAULT_PAGE, DEFAULT_PAGES_SIZE } from '@/constants'
import {
  TError,
  TGetListTopicParams,
  TNetworkResponse,
  TResponse,
  TTopic
} from '@/types'
import { TopicDTO } from '@/types/dto'
import { mapSnakeCaseToCamelCase } from '@/utils'
import { axiosClient } from './Axios'

export const getListTopicRequest = async (
  props?: TGetListTopicParams
): Promise<TResponse<TTopic[]>> => {
  try {
    const params: Record<string, any> = {
      'populate[products][populate][0]': 'cover_image',
      'pagination[page]': props?.page ?? DEFAULT_PAGE,
      'pagination[pageSize]': props?.pageSize ?? DEFAULT_PAGES_SIZE,
      populate: 'cover_image',
      sort: props?.sort ?? 'createdAt:desc'
    }

    const { data } = await axiosClient.get<TNetworkResponse<TopicDTO[]>, any>({
      path: 'topics',
      params
    })

    return {
      data:
        data?.data?.map((e) => {
          return mapSnakeCaseToCamelCase<TTopic>(e)
        }) ?? [],
      pagination: data.meta.pagination
    }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}

export const getListTopicFilterRequest = async (
  props: TGetListTopicParams
): Promise<TResponse<TTopic[]>> => {
  try {
    const params: Record<string, any> = {
      'pagination[page]': props?.page ?? DEFAULT_PAGE,
      'pagination[pageSize]': props?.pageSize ?? DEFAULT_PAGES_SIZE,
      sort: props.sort ?? 'title:asc'
    }

    const { data } = await axiosClient.get<TNetworkResponse<TopicDTO[]>, any>({
      path: 'topics',
      params
    })

    return {
      data: data.data?.map((e) => mapSnakeCaseToCamelCase(e)),
      pagination: data?.meta?.pagination
    }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}
