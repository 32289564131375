import { TError, TProductState } from '@/types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
  getListProductThunk,
  getListRelatedProductThunk,
  getProductByIdThunk
} from '../thunks'

const initialState: TProductState = {
  listProduct: [],
  listRelatedProduct: []
}

export const ProductSlice = createSlice({
  name: 'ProductSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /** GET LIST PRODUCT */
    builder.addCase(getListProductThunk.pending, (state) => {
      state.fetchingListProduct = true
    })
    builder.addCase(getListProductThunk.fulfilled, (state, { payload }) => {
      state.listProduct = payload.data
      state.listProductPagination = payload.pagination
      state.fetchingListProduct = false
      state.errorFetchListProduct = undefined
    })
    builder.addCase(getListProductThunk.rejected, (state, { payload }) => {
      state.fetchingListProduct = false
      state.errorFetchListProduct = payload as TError
    })

    /** GET PRODUCT BY ID */
    builder.addCase(getProductByIdThunk.pending, (state) => {
      state.fetchingProductById = true
    })
    builder.addCase(getProductByIdThunk.fulfilled, (state, action) => {
      state.productDetail = action.payload
      state.fetchingProductById = false
      state.errorFetchProductById = undefined
    })
    builder.addCase(getProductByIdThunk.rejected, (state, action) => {
      state.fetchingProductById = false
      state.errorFetchProductById = action.payload as TError
    })

    /** GET LIST RELATED PRODUCT */
    builder.addCase(getListRelatedProductThunk.pending, (state) => {
      state.fetchingListRelatedProduct = true
    })
    builder.addCase(getListRelatedProductThunk.fulfilled, (state, action) => {
      state.listRelatedProduct = action.payload.data
      state.listRelatedProductPagination = action.payload.pagination
      state.fetchingListRelatedProduct = false
      state.errorFetchListRelatedProduct = undefined
    })
    builder.addCase(getListRelatedProductThunk.rejected, (state, action) => {
      state.fetchingListRelatedProduct = false
      state.errorFetchListRelatedProduct = action.payload as TError
    })
  }
})

export const selectProductState = (state: RootState) => state.productState

export default ProductSlice.reducer
