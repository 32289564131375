import styles from '@/styles/components/CarouselWrapper.module.scss'
import { memo } from 'react'
import Slider, { CustomArrowProps, Settings } from 'react-slick'

const CustomPrevArrow = (props: CustomArrowProps) => {
  const { className, style, onClick } = props

  return (
    <div className={styles.slickArrow} style={{ left: -32 }} onClick={onClick}>
      <i className='fa-solid fa-angle-left' />
    </div>
  )
}

const CustomNextArrow = (props: CustomArrowProps) => {
  const { className, style, onClick } = props

  return (
    <div className={styles.slickArrow} style={{ right: -32 }} onClick={onClick}>
      <i className='fa-solid fa-angle-right' />
    </div>
  )
}

type CarouselWrapperProps = {
  containerClassName?: string
} & Settings

export const CarouselWrapper = memo((props: CarouselWrapperProps) => {
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    autoplay: true,
    swipe: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: styles.slider,
    pauseOnDotsHover: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div className={`${styles.container} ${props.containerClassName}`}>
      <Slider {...settings} {...props}>
        {props.children}
      </Slider>
    </div>
  )
})
