import { camelCase } from 'lodash'

export const mapSnakeCaseToCamelCase = <T>(_inputObj: any): T => {
  return Object.keys(_inputObj).reduce((acc: any, key: string) => {
    if (Array.isArray(_inputObj[key])) {
      Object.assign(acc, {
        [camelCase(key)]: _inputObj[key].map((item: any) =>
          mapSnakeCaseToCamelCase(item)
        )
      })
    } else if (
      typeof _inputObj[key] === 'object' &&
      _inputObj[key] !== undefined &&
      _inputObj[key] !== null
    ) {
      Object.assign(acc, {
        [camelCase(key)]: mapSnakeCaseToCamelCase(_inputObj[key])
      })
    } else {
      Object.assign(acc, { [camelCase(key)]: _inputObj[key] })
    }
    return acc
  }, {})
}
