import { useAppDispatch, useAppSelector, useModalHandler } from '@/hooks'
import { selectAuthState } from '@/redux/slices'
import { deleteAccountThunk } from '@/redux/thunks'
import styles from '@/styles/components/DeleteAccountModalView.module.scss'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { ModalWrapper } from './ModalWrapper'

type DeleteAccountModalViewProps = {
  modalHandler: ReturnType<typeof useModalHandler>
}

export const DeleteAccountModalView = (props: DeleteAccountModalViewProps) => {
  const _dispatch = useAppDispatch()
  const { currentUser, fetchingDeleteAccount } = useAppSelector(selectAuthState)

  const { modalHandler } = props

  const _onCancel = () => !fetchingDeleteAccount && modalHandler.hide()
  const _onDelete = () => {
    if (currentUser) return _dispatch(deleteAccountThunk(currentUser.id))
  }

  return (
    <ModalWrapper
      footer={null}
      maskClosable={!fetchingDeleteAccount}
      closable={!fetchingDeleteAccount}
      modalHandler={modalHandler}
    >
      <div className={styles.deleteAccountModalViewContainer}>
        <p className={styles.warningTitle}>
          Bạn có chắc muốn xoá tài khoản này?
        </p>
        <p className={styles.warningDescription}>
          Hành động này sẽ không thể hoàn tác!
        </p>
        <div className='d-flex align-self-end gap-4 hover'>
          <button className={styles.cancelButton} onClick={_onCancel}>
            Huỷ
          </button>
          <button className={styles.deleteButton} onClick={_onDelete}>
            {fetchingDeleteAccount && (
              <Spin
                indicator={<LoadingOutlined spin style={{ color: 'white' }} />}
              />
            )}
            {!fetchingDeleteAccount && 'Xoá'}
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}
