import { useEffect } from 'react'

const SalesPoliciesPageView = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='page-content'>
      {/* contact area */}
      <section className='content-inner-1 shop-account'>
        <div className='container'>
          <p
            className='text-center'
            style={{ fontWeight: 600, color: '#eb3c23', fontSize: 24 }}
          >
            NGUYÊN TẮC NHẬN - TRẢ SẢN PHẨM
          </p>
          <div className='row'>
            {/* Left part start */}
            <div className='col-lg-8 col-md-7 col-sm-12 inner-text w-100'>
              <h4 className='title'>Tư vấn, nhận đơn dịch vụ:</h4>
              <ul className='list-check primary m-a0'>
                <li>
                  Khách hàng điền thông tin vào{' '}
                  <strong style={{ color: 'black' }}>
                    <i>Bảng mô tả ban đầu (Description)</i>
                  </strong>{' '}
                  đối với sản phẩm theo chương trình dự án và lại Bảng{' '}
                  <strong style={{ color: 'black' }}>
                    <i>Thông tin thiết kế</i>
                  </strong>{' '}
                  đối với sản phẩm theo combo của SenCAM.
                </li>
                <li>
                  Dựa vào thông tin của khách hàng, SenCAM sẽ tư vấn và gửi{' '}
                  <strong style={{ color: 'black' }}>
                    <i>Bảng báo giá</i>
                  </strong>{' '}
                  dịch vụ thiết kế phù hợp.
                </li>
              </ul>
              <p />
              <div className='dlab-divider bg-gray-dark'></div>
              <h4 className='title'>Thiết kế, sản xuất sản phẩm:</h4>
              <ul className='list-check primary m-a0'>
                <li>
                  SenCAM và khách hàng thống nhất thời gian gửi sản phẩm demo
                  lần 1, 2, 3, thời gian gửi lại phản hồi từ khách hàng, thời
                  gian gửi sản phẩm final và thời gian thanh toán dịch vụ thông
                  qua bảng{' '}
                  <strong style={{ color: 'black' }}>
                    <i>Tiến độ giao nhận sản phẩm.</i>
                  </strong>
                </li>
                <li>
                  Khách hàng có 03 lần chỉnh sửa thiết kế, sau 03 lần nếu có
                  chỉnh sửa thêm sẽ tính phí 10% chi phí đơn (Không thay đổi nội
                  dung yêu cầu ban đầu). Trường hợp khách hàng thay đổi nội dung
                  so với yêu cầu ban đầu, SenCAM tính thêm phí 01 lần sửa đổi là
                  30% chi phí đơn hàng.
                </li>
                <li>
                  Khách hàng sau khi đã thống nhất chi phí thiết kế sẽ thanh
                  toán lần đầu 30% tổng chi phí thiết kế, thanh toán lần 2 30%
                  và sau khi nhận sản phẩm hoàn chỉnh sẽ thanh toán 40% chi phí
                  còn lại. Chi tiết thanh toán sẽ được cập nhật tại file{' '}
                  <strong style={{ color: 'black' }}>
                    <i>Tiến độ giao nhận sản phẩm.</i>
                  </strong>{' '}
                  <i>
                    (Khách hàng có thể thương lượng lại về quy trình thanh toán,
                    tuy nhiên{' '}
                    <strong style={{ color: 'black' }}>
                      thanh toán lần đầu không dưới 30%
                    </strong>{' '}
                    tổng giá trị đơn hàng)
                  </i>
                </li>
              </ul>
              <p />
              <div className='dlab-divider bg-gray-dark'></div>
              <h4 className='title'> Giao trả sản phẩm hoàn chỉnh:</h4>
              <ul className='list-check primary m-a0'>
                <li>
                  Sau khi đã chỉnh sửa theo các ý kiến phản hồi của khách hàng
                  và hoàn thiện sản phẩm cuối cùng, đơn vị thiết kế sẽ gửi sản
                  phẩm hoàn chỉnh kèm file thiết kế có thể chỉnh sửa cho khách
                  hàng trên link drive để khách hàng lưu trữ và tải về.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Privacy Policy END */}
    </div>
  )
}

export default SalesPoliciesPageView
