import { ReactComponent as GoogleSVG } from '@/assets/icons/google.svg'
import { useGoogleLogin } from '@react-oauth/google'
import { memo } from 'react'

export const GoogleSignInButton = memo(() => {
  const onLoginGoogle = useGoogleLogin({
    onSuccess: (_response) => {
      const _url = `connect/google/redirect?access_token=${_response.access_token}`
      window.location.href = _url
    },
    onError: (error) => {
      // console.log({ error })
    },
    onNonOAuthError: (nonOAuthError) => {
      // console.log({ nonOAuthError })
    }
  })

  return (
    <button
      type='button'
      className='social-icon-button'
      onClick={() => onLoginGoogle()}
    >
      <GoogleSVG width={38} height={38} />
    </button>
  )
})
