import { ROUTER, STORAGE_KEY } from '@/constants'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const SocialLoginRedirect = () => {
  const [text, setText] = useState('Loading...')
  const _params = useParams()
  const _location = useLocation()

  const [emailExist, setEmailExist] = useState(false)

  const goHome = () => window.location.replace(ROUTER.HOME)
  const _handleRedirect = async () => {
    try {
      const _url = `${process.env.REACT_APP_BASE_URL}/auth/${_params.providerName}/callback${_location.search}`
      const _response = await fetch(_url)

      if (_response.status !== 200) {
        throw new Error(`Couldn't login to SENCAM. Status: ${_response.status}`)
      }

      const _json = await _response.json()

      window.localStorage.setItem(STORAGE_KEY.IS_LOGGED_IN, 'true')
      window.localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, _json.jwt)

      const _indicator = document.getElementById('indicator')
      _indicator?.setAttribute('style', 'display: block')

      setText(
        `Bạn đã đăng nhập thành công. Bạn sẽ được chuyển hướng về trang chủ trong vài giây nữa...`
      )
      setTimeout(goHome, 1500)
    } catch (error) {
      setText('')
      setEmailExist(true)
      setTimeout(goHome, 5000)
    }
  }

  useEffect(() => {
    _handleRedirect()
  }, [_location.search, _params.providerName])

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div id='indicator' style={{ display: 'none' }}>
        <Spin
          indicator={<LoadingOutlined spin style={{ color: '#eb3c23' }} />}
          size='large'
        />
      </div>
      <p>{text}</p>
      {emailExist && (
        <>
          <p>Đăng nhập thất bại, Email đã được sử dụng.</p>
          <p>Bạn sẽ được chuyển hướng về trang chủ trong vài giây nữa...</p>
          <Button onClick={goHome}>Trang Chủ</Button>
        </>
      )}
    </div>
  )
}

export default SocialLoginRedirect
