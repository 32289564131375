import defaultImage from '@/assets/images/defaultTopic.jpg'
import { DEFAULT_PRODUCT_PAGES_SIZE, ROUTER } from '@/constants'
import { AuthModalContext } from '@/contexts'
import { useAppDispatch, useAppSelector, usePrevious } from '@/hooks'
import { selectProductState } from '@/redux/slices'
import { getListRelatedProductThunk, getProductByIdThunk } from '@/redux/thunks'
import { axiosClient } from '@/services'
import styles from '@/styles/page/ProductDetailPageView.module.scss'
import { notification } from 'antd'
import { HttpStatusCode } from 'axios'
import { saveAs } from 'file-saver'
import { useContext, useEffect, useLayoutEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const ProductDetailPageView = () => {
  const _navigate = useNavigate()
  const _dispatch = useAppDispatch()
  const { isLoggedIn, openAuthModal } = useContext(AuthModalContext)
  const {
    productDetail,
    listRelatedProduct,
    fetchingProductById,
    errorFetchProductById
  } = useAppSelector(selectProductState)
  const [api, contextHolder] = notification.useNotification()

  const [searchParams, setSearchParams] = useSearchParams()

  const _productId = searchParams.get('id')
  const _prevFetchingProductById = usePrevious(fetchingProductById)
  const _listRelatedProductDisplay = listRelatedProduct.filter(
    (e) => e.id !== Number(_productId)
  )

  const _onClickDownload = async () => {
    if (!isLoggedIn) return openAuthModal()

    try {
      const { data } = await axiosClient.get({
        path: `products/${Number(_productId)}`,
        params: { populate: 'download_file' }
      })
      //@ts-ignore
      const download_file = data?.data.download_file
      if (download_file) saveAs(download_file.url, download_file.name)
      else api.error({ message: 'Có lỗi xảy ra' })
    } catch (error) {
      api.error({ message: 'Có lỗi xảy ra' })
    }
  }

  const _onClickTopic = (topicId: number) => {
    _navigate({
      pathname: ROUTER.PRODUCTS,
      search: `topic=${topicId}&page=1&pageSize=${DEFAULT_PRODUCT_PAGES_SIZE}&sort=createdAt:desc`
    })
  }

  useLayoutEffect(() => {
    if (_productId) {
      window.scrollTo(0, 0)
      _dispatch(getProductByIdThunk(Number(_productId)))
      _dispatch(
        getListRelatedProductThunk({
          populate: 'cover_image',
          listTopicId: searchParams.get('topic')?.split(','),
          pageSize: 5
        })
      )
    }
  }, [searchParams])

  useEffect(() => {
    if (_prevFetchingProductById && !fetchingProductById) {
      if (errorFetchProductById?.status === HttpStatusCode.NotFound) {
        _navigate(ROUTER.HOME)
      }
    }
  }, [_prevFetchingProductById, errorFetchProductById])

  return (
    <>
      {contextHolder}
      <div className={`page-content bg-grey ${styles.container}`}>
        <section className='content-inner-1'>
          <div className='container'>
            <div className='row book-grid-row style-4 m-b60'>
              <div className='dz-box flex-column'>
                <div className={`d-flex mb-4 ${styles.bannerWrapper}`}>
                  <div className='dz-media' style={{ flex: 2 }}>
                    <img
                      src={productDetail?.coverImage?.url || defaultImage}
                      alt='book'
                      style={{ maxHeight: 500, objectFit: 'contain' }}
                      className='fade-in-3s'
                    />
                  </div>
                  <div className={`dz-header ${styles.productName1}`}>
                    <h4 className='title' style={{ fontSize: 16 }}>
                      {productDetail?.name}
                    </h4>
                  </div>
                  <div
                    className={`book-footer ${styles.downloadButtonWrapper}`}
                  >
                    <a
                      onClick={_onClickDownload}
                      className='btn btn-primary btnhover2 px-4'
                    >
                      <span>Tải xuống</span>
                    </a>
                    <div className='product-description mt-3 tabs-site-button'>
                      <span className={styles.textProductDetail}>
                        Chi tiết sản phẩm
                      </span>
                      <div className='tab-content'>
                        <div
                          id='graphic-design-1'
                          className='tab-pane show active'
                        >
                          <table className='table border book-overview'>
                            <tbody>
                              <tr>
                                <th>Tên sản phẩm</th>
                                <td>{productDetail?.name}</td>
                              </tr>
                              <tr>
                                <th>Loại file</th>
                                <td>
                                  {productDetail?.fileExtensions?.join(' ') ??
                                    '(Chưa xác định)'}
                                </td>
                              </tr>
                              <tr>
                                <th>Chủ đề</th>
                                <td>
                                  {productDetail?.topics?.map(
                                    (topic, index) => {
                                      return (
                                        <span
                                          key={index}
                                          style={{ display: 'block' }}
                                          onClick={() => {
                                            _onClickTopic(topic.id)
                                          }}
                                          className={`p-0 py-1 ${styles.productTopicName}`}
                                        >
                                          {topic.title}
                                        </span>
                                      )
                                    }
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Kích thước</th>
                                <td>
                                  {productDetail?.coverImage?.width}x
                                  {productDetail?.coverImage?.height}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`dz-header ${styles.productName2}`}>
                  <h4 className='title' style={{ fontSize: 16 }}>
                    {productDetail?.name}
                  </h4>
                </div>
              </div>
            </div>

            <div className='widget'>
              <h4 className='widget-title' style={{ fontSize: 20 }}>
                Sản phẩm có liên quan
              </h4>
              <div className={styles.relatedProductsContainer}>
                {!_listRelatedProductDisplay.length && (
                  <span>Chưa có sản phẩm liên quan</span>
                )}
                {_listRelatedProductDisplay.map((product) => {
                  const _onClickViewDetail = () => {
                    const _listTopicId = product.topics?.map((e) => e.id)
                    setSearchParams((prev) => {
                      prev.set('id', product.id.toString())
                      _listTopicId?.length &&
                        prev.set('topic', _listTopicId.toString())
                      return prev
                    })
                  }

                  return (
                    <div
                      key={product.id}
                      className={styles.relatedProduct}
                      onClick={_onClickViewDetail}
                    >
                      <div className={styles.thumbnailWrapper}>
                        <img
                          alt='book15'
                          src={product.coverImage?.url}
                          className={styles.thumbnail}
                        />
                      </div>

                      <div className={styles.relatedProductInfo}>
                        <h5
                          className='truncate-2'
                          style={{ flex: 1, fontSize: 16 }}
                        >
                          {product.name}
                        </h5>
                        <a
                          href='javascript:void(0);'
                          className='btn btn-primary btn-sm btnhover2'
                        >
                          Xem chi tiết
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
