import { getListProductRequest, getProductByIdRequest } from '@/services'
import { TGetListProductParams } from '@/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getListProductThunk = createAsyncThunk(
  'ProductAsyncThunk/getListProductThunk',
  async (
    payload: TGetListProductParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { data, pagination, error } = await getListProductRequest(payload)

    if (data) return fulfillWithValue({ data, pagination })
    return rejectWithValue(error)
  }
)

export const getProductByIdThunk = createAsyncThunk(
  'ProductAsyncThunk/getProductByIdThunk',
  async (payload: number, { fulfillWithValue, rejectWithValue }) => {
    const { data, error } = await getProductByIdRequest(payload)
    if (data) return fulfillWithValue(data)
    return rejectWithValue(error)
  }
)

export const getListRelatedProductThunk = createAsyncThunk(
  'ProductAsyncThunk/getListRelatedProductThunk',
  async (
    payload: TGetListProductParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { data, error, pagination } = await getListProductRequest(payload)
    if (data) return fulfillWithValue({ data, pagination })
    return rejectWithValue(error)
  }
)
