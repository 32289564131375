import {
  TError,
  TProduct,
  TResponse,
  TSearchResponse,
  TSearchSuggestionResponse,
  TTopic
} from '@/types'
import { SearchDTO, SearchSuggestionDTO } from '@/types/dto'
import { mapSnakeCaseToCamelCase } from '@/utils'
import { axiosClient } from './Axios'

export const getSearchRequest = async (
  key?: string
): Promise<TResponse<TSearchResponse>> => {
  try {
    const params = new URLSearchParams()
    key && params.set('keyword', key)

    const { data } = await axiosClient.get<SearchDTO, any>({
      path: 'search',
      params
    })

    return {
      data: {
        products: data.products.map((e) => {
          return mapSnakeCaseToCamelCase<
            Pick<TProduct, 'id' | 'name' | 'coverImage' | 'topics'>
          >(e)
        }),
        topics: data.topics.map((e) => {
          return mapSnakeCaseToCamelCase<
            Pick<TTopic, 'id' | 'title' | 'coverImage'>
          >(e)
        })
      }
    }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}

export const getSearchSuggestionRequest = async (): Promise<
  TResponse<TSearchSuggestionResponse>
> => {
  try {
    const { data } = await axiosClient.get<SearchSuggestionDTO, any>({
      path: 'search/suggestion'
    })

    return {
      data: {
        keywords: data.keywords,
        products: data.products.map((e) => mapSnakeCaseToCamelCase(e))
      }
    }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}

export const submitSearchCountRequest = async (
  productId: number
): Promise<TResponse<{ result: boolean }>> => {
  try {
    const { data } = await axiosClient.get<{ result: boolean }, any>({
      path: `search/count?productId=${productId}`
    })

    return { data }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}
