const NotFoundView = () => {
  return (
    <div className='page-wraper'>
      <div className='error-page overlay-secondary-dark'>
        <div className='error-inner text-center'>
          <div className='dz_error' data-text='404'>
            404
          </div>
          <h2 className='error-head'>
            Chúng tôi xin lỗi. Nhưng trang bạn đang tìm kiếm không thể tìm thấy.
          </h2>
          <a
            href='/'
            className='btn btn-primary btn-border btnhover white-border'
          >
            QUAY VỀ TRANG CHỦ
          </a>
        </div>
      </div>
    </div>
  )
}

export default NotFoundView
