import styles from '@/styles/components/PublicTimeSelect.module.scss'
import { ConfigProvider, Select, SelectProps } from 'antd'

type PublicTimeSelectProps = {} & SelectProps

export const PublicTimeSelect = (props: PublicTimeSelectProps) => {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: 'white' } }}>
      <Select
        suffixIcon={<i className='fas fa-sort-amount-down text-secondary' />}
        className={styles.container}
        {...props}
      />
    </ConfigProvider>
  )
}
