import { useAuthContext } from '@/contexts'
import styles from '@/styles/components/ProductItem.module.scss'
import { TProduct, TSearchResponse } from '@/types'
import { memo } from 'react'
import defaultImage from '@/assets/images/defaultTopic.jpg'

type ProductItemProps = {
  data: TProduct | TSearchResponse['products'][0]
  onClick?: (productId: number, listTopicId?: number[]) => void
}

export const ProductItem = memo((props: ProductItemProps) => {
  const { data, onClick } = props

  const { isLoggedIn, openAuthModal } = useAuthContext()

  const _goToDetail = () => {
    if (onClick && data) {
      return onClick(
        data.id,
        data.topics?.map((e) => e.id)
      )
    }
  }

  const _onClickDownLoad = () => {
    if (isLoggedIn) return console.log('ready to download')
    openAuthModal()
  }

  return (
    <div className='fade-in-3s col-book style-2' style={{ display: 'block' }}>
      <div onClick={_goToDetail} className={styles.container}>
        <img
          src={
            (data.coverImage?.url ?? data.coverImage?.formats.large.url) ||
            defaultImage
          }
          className={styles.thumbnail}
          alt='book'
        />

        <div className={styles.productContent}>
          <ul className={`dz-tags ${styles.tagWrapper}`}>
            {data?.topics?.slice(0, 3)?.map((e, i) => {
              return (
                <li key={i}>
                  <span className={styles.tag}>{e.title}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
})
