import profile0 from '@/assets/images/profile0.png'
import { DeleteAccountModalView } from '@/components'
import { ROUTER } from '@/constants'
import {
  useAppDispatch,
  useAppSelector,
  useModalHandler,
  usePrevious
} from '@/hooks'
import { authActions, selectAuthState } from '@/redux/slices'
import { notification } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ProfileView = () => {
  const _navigate = useNavigate()
  const _dispatch = useAppDispatch()

  const { currentUser, fetchingDeleteAccount, errorDeleteAccount } =
    useAppSelector(selectAuthState)

  const [api, contextHolder] = notification.useNotification()
  const _prevDeleteAccount = usePrevious(fetchingDeleteAccount)
  const _deleteAccountModal = useModalHandler()

  const _onLogOut = () => {
    _dispatch(authActions.logOut())
    _navigate(ROUTER.HOME, { replace: true })
  }

  useEffect(() => {
    if (_prevDeleteAccount && !fetchingDeleteAccount) {
      if (errorDeleteAccount) {
        return api.error({ message: 'Xoá tài khoản thất bại' })
      }

      _deleteAccountModal.hide()
      _onLogOut()
    }
  }, [_prevDeleteAccount, errorDeleteAccount])

  return (
    <div className='page-content bg-white'>
      {contextHolder}

      {/* contact area */}
      <div className='content-block'>
        {/* Browse Jobs */}
        <section className='content-inner bg-white'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-3 col-lg-4 m-b30'>
                <div className='sticky-top'>
                  <div className='shop-account'>
                    <div className='account-detail text-center'>
                      <div className='my-image'>
                        <a href='javascript:void(0);'>
                          <img alt='' src={profile0} />
                        </a>
                      </div>
                      <div className='account-title'>
                        <div className=''>
                          <h4 className='m-b5'>
                            <a href='javascript:void(0);'>
                              {currentUser?.username}
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href='javascript:void(0);'>
                          <i className='far fa-user' aria-hidden='true'></i>
                          <span>Cá Nhân</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href='javascript:void(0);'
                          onClick={_deleteAccountModal.show}
                        >
                          <i className='fa-solid fa-trash'></i>
                          <span>Xoá tài khoản</span>
                        </a>
                      </li>
                      {/* <li>
                        <a href='shop-cart.html'>
                          <i className='flaticon-shopping-cart-1'></i>
                          <span>My Cart</span>
                        </a>
                      </li>
                      <li>
                        <a href='wishlist.html'>
                          <i className='far fa-heart' aria-hidden='true'></i>
                          <span>Wishlist</span>
                        </a>
                      </li>
                      <li>
                        <a href='books-grid-view.html'>
                          <i className='fa fa-briefcase' aria-hidden='true'></i>
                          <span>Shop</span>
                        </a>
                      </li>
                      <li>
                        <a href='services.html'>
                          <i className='far fa-bell' aria-hidden='true'></i>
                          <span>Services</span>
                        </a>
                      </li>
                      <li>
                        <a href='help-desk.html'>
                          <i className='far fa-id-card' aria-hidden='true'></i>
                          <span>Help Desk</span>
                        </a>
                      </li>
                      <li>
                        <a href='privacy-policy.html'>
                          <i className='fa fa-key' aria-hidden='true'></i>
                          <span>Privacy Policy</span>
                        </a>
                      </li> */}
                      <li>
                        <a href='javascript:void(0);' onClick={_onLogOut}>
                          <i
                            className='fas fa-sign-out-alt'
                            aria-hidden='true'
                          />
                          <span>Đăng Xuất</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-xl-9 col-lg-8 m-b30'>
                <div className='shop-bx shop-profile'>
                  <div className='shop-bx-title clearfix'>
                    <h5 className='text-uppercase'>Thông tin cơ bản</h5>
                  </div>
                  <form>
                    <div className='row m-b30'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='mb-3'>
                          <label
                            htmlFor='formcontrolinput1'
                            className='form-label'
                          >
                            Tên:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='formcontrolinput1'
                            disabled
                            value={currentUser?.username}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <div className='mb-3'>
                          <label
                            htmlFor='formcontrolinput2'
                            className='form-label'
                          >
                            Email
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='formcontrolinput2'
                            disabled
                            value={currentUser?.email}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 col-md-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlTextarea'
                            className='form-label'
                          >
                            Description:
                          </label>
                          <textarea
                            className='form-control'
                            id='exampleFormControlTextarea'
                            rows={5}
                            disabled
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {/* <div className='shop-bx-title clearfix'>
                      <h5 className='text-uppercase'>Contact Information</h5>
                    </div> */}
                    {/* <div className='row'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='mb-3'>
                          <label
                            htmlFor='formcontrolinput5'
                            className='form-label'
                          >
                            Contact Number:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='formcontrolinput5'
                            placeholder='+1 123 456 7890'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <div className='mb-3'>
                          <label
                            htmlFor='formcontrolinput6'
                            className='form-label'
                          >
                            Email Address:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='formcontrolinput6'
                            placeholder='info@example.com'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <div className='mb-3'>
                          <label
                            htmlFor='formcontrolinput7'
                            className='form-label'
                          >
                            Country:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='formcontrolinput7'
                            placeholder='Country Name'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <div className='mb-3'>
                          <label
                            htmlFor='formcontrolinput8'
                            className='form-label'
                          >
                            Postcode:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='formcontrolinput8'
                            placeholder='112233'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <div className='mb-3'>
                          <label
                            htmlFor='formcontrolinput9'
                            className='form-label'
                          >
                            City:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='formcontrolinput9'
                            placeholder='City Name'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6'>
                        <div className='mb-4'>
                          <label
                            htmlFor='formcontrolinput10'
                            className='form-label'
                          >
                            Full Address:
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='formcontrolinput10'
                            placeholder='New york City'
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <button className='btn btn-primary btnhover'>
                      Save Setting
                    </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Browse Jobs END */}
      </div>

      <DeleteAccountModalView modalHandler={_deleteAccountModal} />
    </div>
  )
}

export default ProfileView
