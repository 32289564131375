import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ROUTER } from './constants'
import { AppLayout, AuthLayout } from './layouts'
import {
  ComplaintPolicyPageView,
  ContactUsPageView,
  ForgotPasswordView,
  HomeView,
  ListProductsPageView,
  ListTopicPageView,
  NotFoundView,
  PrivacyPolicyPageView,
  ProductDetailPageView,
  ProfileView,
  RequestServicePageView,
  SalesPoliciesPageView,
  SearchPageView,
  SignInView,
  SignUpView,
  SocialLoginRedirect
} from './pages'
import { PrivateRoute, PublicRoute } from './routers'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route element={<AuthLayout />}>
            {/* <Route path={ROUTER.LOGIN} element={<SignInView />} /> */}
            <Route path={ROUTER.REGISTER} element={<SignUpView />} />
            {/* <Route
              path={ROUTER.FORGOT_PASSWORD}
              element={<ForgotPasswordView />}
            /> */}
            <Route
              path='/connect/:providerName/redirect'
              element={<SocialLoginRedirect />}
            />
          </Route>
        </Route>

        <Route element={<PrivateRoute />}>
          <Route element={<AppLayout />}>
            <Route index path={ROUTER.HOME} element={<HomeView />} />
            <Route path={ROUTER.USER_PROFILE} element={<ProfileView />} />
            <Route path={ROUTER.PRODUCTS} element={<ListProductsPageView />} />
            <Route
              path={ROUTER.PRODUCT_DETAIL}
              element={<ProductDetailPageView />}
            />
            {/* <Route path={ROUTER.CONTACT_US} element={<ContactUsPageView />} /> */}
            <Route path={ROUTER.TOPIC} element={<ListTopicPageView />} />
            <Route
              path={ROUTER.PRIVACY_POLICY}
              element={<PrivacyPolicyPageView />}
            />
            <Route
              path={ROUTER.CUSTOMER_COMPLAINT_POLICY}
              element={<ComplaintPolicyPageView />}
            />
            <Route
              path={ROUTER.REQUEST_SERVICE}
              element={<RequestServicePageView />}
            />
            <Route
              path={ROUTER.SALES_POLICIES}
              element={<SalesPoliciesPageView />}
            />
            <Route path={ROUTER.SEARCH} element={<SearchPageView />} />
          </Route>
        </Route>

        <Route path={ROUTER.NOT_FOUND} element={<NotFoundView />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
