import { TError, TWebConfigState } from '@/types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'
import { getWebConfigThunk } from '../thunks'

const initialState: TWebConfigState = {}

export const WebConfigSlice = createSlice({
  name: 'WebConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWebConfigThunk.pending, (state) => {
      state.fetchingWebConfig = true
    })
    builder.addCase(getWebConfigThunk.fulfilled, (state, action) => {
      state.webConfig = action.payload
      state.fetchingWebConfig = false
      state.errorFetchWebConfig = undefined
    })
    builder.addCase(getWebConfigThunk.rejected, (state, action) => {
      state.fetchingWebConfig = false
      state.errorFetchWebConfig = action.payload as TError
    })
  }
})

export const selectWebConfigState = (state: RootState) => state.webConfigState

export default WebConfigSlice.reducer
