import { DEFAULT_PAGE, DEFAULT_PAGES_SIZE } from '@/constants'
import {
  TError,
  TGetListProductParams,
  TNetworkResponse,
  TProduct,
  TResponse
} from '@/types'
import { ProductDTO } from '@/types/dto'
import { mapSnakeCaseToCamelCase } from '@/utils'
import { axiosClient } from './Axios'

export const getListProductRequest = async (
  props?: TGetListProductParams
): Promise<TResponse<TProduct[]>> => {
  try {
    const params: Record<string, any> = {
      populate: props?.populate,
      'pagination[page]': props?.page ?? DEFAULT_PAGE,
      'pagination[pageSize]': props?.pageSize ?? DEFAULT_PAGES_SIZE,
      sort: props?.sort ?? 'createdAt:desc'
    }

    if (props?.listTopicId?.length) {
      props.listTopicId.forEach((topicId, index) => {
        params[`filters[topics][id][$in][${index}]`] = topicId
      })
    }

    const { data } = await axiosClient.get<TNetworkResponse<ProductDTO[]>, any>(
      {
        path: 'products',
        params
      }
    )
    return {
      data: data?.data?.map((e) => mapSnakeCaseToCamelCase<TProduct>(e)) ?? [],
      pagination: data.meta.pagination
    }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}

export const getProductByIdRequest = async (
  productId: number
): Promise<TResponse<TProduct>> => {
  try {
    const params: Record<string, any> = {
      populate: 'topics,cover_image'
    }

    const { data } = await axiosClient.get<TNetworkResponse<ProductDTO>, any>({
      path: `products/${productId}`,
      params
    })

    return {
      data: {
        ...mapSnakeCaseToCamelCase<TProduct>(data?.data),
        fileExtensions: data.data?.file_extensions || null
      }
    }
  } catch (error) {
    const e = error as { data: null; error: TError }
    return { error: e.error }
  }
}
